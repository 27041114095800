import { useContext } from 'react'
import {
  UNSAFE_DataRouterContext,
  UNSAFE_NavigationContext,
  useLocation,
  useNavigate,
} from 'react-router' // changed from react-router-dom
import { PartialLocation, QueryParamAdapterComponent } from 'use-query-params'

const ReactRouter7Adapter: QueryParamAdapterComponent = ({ children }) => {
  // we need the navigator directly so we can access the current version
  // of location in case of multiple updates within a render (e.g. #233)
  // but we will limit our usage of it and have a backup to just use
  // useLocation() output in case of some kind of breaking change we miss.
  // see: https://github.com/remix-run/react-router/blob/f3d87dcc91fbd6fd646064b88b4be52c15114603/packages/react-router-dom/index.tsx#L113-L131
  const { navigator } = useContext(UNSAFE_NavigationContext)
  const navigate = useNavigate()
  const router = useContext(UNSAFE_DataRouterContext)?.router
  const location = useLocation()

  const adapter = {
    replace(location: PartialLocation) {
      navigate(location.search || '?', {
        replace: true,
        state: location.state,
      })
    },
    push(location: PartialLocation) {
      navigate(location.search || '?', {
        replace: false,
        state: location.state,
      })
    },
    get location() {
      // be a bit defensive here in case of an unexpected breaking change in React Router
      return router?.state?.location ?? location
    },
  }

  return children(adapter)
}

export default ReactRouter7Adapter
