import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react'

export enum GlobalModalId {
  EXPORT_REPORTS = 'export_reports',
  EXPORT_DATA = 'export_data',
  PROFILE_SETTINGS = 'profile_settings',
  BIKESHARE_STATION_REPORTS = 'bikeshare_station_reports',
}

interface GlobalModalContextProps {
  id: GlobalModalId | undefined
  setId: Dispatch<SetStateAction<GlobalModalId | undefined>>
}

const GlobalModalContext = createContext<GlobalModalContextProps | undefined>(undefined)

export const useGlobalModalProvider = () => {
  const context = useContext(GlobalModalContext)
  if (context === undefined) {
    throw new Error('useGlobalModalProvider must be used within GlobalModalProvider')
  }
  return context
}

const GlobalModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [id, setId] = useState<GlobalModalId | undefined>(undefined)
  return <GlobalModalContext.Provider value={{ id, setId }}>{children}</GlobalModalContext.Provider>
}

export default GlobalModalProvider
