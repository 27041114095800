import { NavbarElementProps } from 'react-day-picker'

import { Icon } from '../../Icon'

import './index.scss'

/**
 * See all props at
 * https://react-day-picker.js.org/api/DayPicker#navbarElement
 */
export const NavbarElement = ({ onNextClick, onPreviousClick }: NavbarElementProps) => {
  return (
    <div className="populus-calendar-navbar">
      <Icon
        className="arrow-left"
        color="black-0"
        icon="ArrowUp"
        onClick={() => onPreviousClick()}
      />
      <Icon className="arrow-right" color="black-0" icon="ArrowUp" onClick={() => onNextClick()} />
    </div>
  )
}
