import { Outlet } from 'react-router'
import { Box } from '@mui/material'

import CurbsNav from '@/components/layouts/CurbsNav'
import { DRAWER_WIDTH_PIXELS } from '@/components/layouts/utils'
import GlobalModal from '@/modules/globalModal'

import './NavigationLayout.scss'

const NavigationLayoutMUI = () => (
  <>
    <GlobalModal />
    <CurbsNav>
      <Box sx={{ ml: `${DRAWER_WIDTH_PIXELS}px`, height: '100vh', overflow: 'auto' }}>
        <Outlet />
      </Box>
    </CurbsNav>
  </>
)
export default NavigationLayoutMUI
