import { Link } from 'react-router'

import { Button, Text } from '@/components/componentLibrary'
import { InvalidRouteTemplate } from '@/components/layouts'
import { Populus404 } from '@/modules/404Routes/Populus404'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'
import { PATHS } from '@/modules/urlRouting/paths'
import { generatePathWithRegionId } from '@/modules/urlRouting/utils'

export const PolicyNotFound = () => {
  const {
    data: { regionId, text },
  } = useCurrentRegion()

  return (
    <InvalidRouteTemplate
      imageContent={<Populus404 />}
      primaryTextContent={
        <div>
          <Text styleType="title-light">{`The policy you selected can't be found`}</Text>
        </div>
      }
      actionsContent={
        <Link to={generatePathWithRegionId(PATHS.MOBILITY.POLICIES._PATH, regionId)}>
          <Button extraWide text={`${text + ' '}Policies Library`} />
        </Link>
      }
    />
  )
}
