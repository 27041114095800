import { al } from '@faker-js/faker/dist/airline-C5Qwd7_q'
import { quantile } from 'd3-array'
import { ceil, floor, round } from 'lodash'

import { getArrayBounds, roundPreciseNumber } from '@/common/utils/numbers'

export interface MapColorScale {
  readonly bins: ColorBin[]
  getMapColor: (value: number) => string
  readonly mapboxStyleStepBins: (string | number)[]
}

export interface ColorBin {
  value: number | string
  displayMin?: number | string
  displayMax?: number | string
  color: string
}

// Some Color Schemes come from https://colorbrewer2.org
export const COLOR_SCHEMES: { [key: string]: string[] } = {
  BLUEBERRY_LEMONADE_BLUE: ['#FFFFD9', '#CAEAB4', '#A3D0BD', '#2C9CC1', '#2D819C'],
  DARK_BLUES: ['#f1f1ff', '#033e6a'],
  BLUEBERRY_LEMONADE_RED: ['#FFE1B4', '#F0B290', '#E1774A', '#D25449', '#C32525'],
  BLUEBERRY_TO_PURPLE_7_BINS: [
    '#edf8fb',
    '#bfd3e6',
    '#9ebcda',
    '#8c96c6',
    '#8c6bb1',
    '#88419d',
    '#6e016b',
  ],
  BLUEBERRY_TO_PURPLE_5_BINS: ['#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
  YELLOW_TO_MAROON: [
    '#FCE470',
    '#F8C151',
    '#E38F36',
    '#DC713C',
    '#C44D3D',
    '#AB2746',
    '#84163D',
    '#610A29',
    '#450119',
  ],
  YELLOW_TO_BLUE_7_BINS: [
    '#FFEDAB',
    '#c7e9b4',
    '#7fcdbb',
    '#41b6c4',
    '#1d91c0',
    '#225ea8',
    '#0c2c84',
  ],
  YELLOW_TO_RED_7_BINS: [
    '#FEF0D9',
    '#FDD49E',
    '#FDBB84',
    '#FC8D59',
    '#EF6548',
    '#D7301F',
    '#990000',
  ],
  BLUE_TO_PURPLE_7_BINS: [
    '#CDE3F9',
    '#B0BEF1',
    '#9485CC',
    '#A45BB7',
    '#7C177E',
    '#5C0D5F',
    '#38004B',
  ],
  RED_TO_BLUE_10_BINS: [
    '#A50026',
    '#F46D43',
    '#FEE090',
    '#EFF3FF',
    '#C6DBEF',
    '#9ECAE1',
    '#6BAED6',
    '#4292C6',
    '#2171B5',
    '#084594',
  ],
  YELLOW_TO_BLUE_10_BINS: [
    '#FFFFD9',
    '#EDF8B1',
    '#C7E9B4',
    '#7FCDBB',
    '#41B6C4',
    '#1D91C0',
    '#225EA8',
    '#253494',
    '#001F75',
    '#001140',
  ],
  BEE_HIVE_7_BINS: ['#FFF3B4', '#FFD361', '#FFAB5E', '#FF6683', '#D01ED3', '#6326C5', '#130060'],
  BLUE_TO_RED_6_BINS: ['#4575b4', '#91bfdb', '#e0f3f8', '#fee090', '#fc8d59', '#d73027'],
}

const ZERO_BIN = { value: 0.00001, color: 'grey' }

export const DEFAULT_COLOR = '#fff'

const getBinsForSmallValueSet = (colors: string[], values: number[]): ColorBin[] => {
  const uniqueValues = [...new Set(values)].sort((a, b) => a - b)
  const binColors = []
  const numberOfColorsToUse = uniqueValues.length
  // if the numberOfColorsToUse is 1 and that value is falsy,  use the default color
  if (numberOfColorsToUse == 1 && !uniqueValues[0]) binColors.push(DEFAULT_COLOR)
  else {
    binColors.push(
      ...colors
        .filter(
          (_, index) => index % Math.floor((colors.length - 1) / (numberOfColorsToUse - 1)) === 0
        )
        .slice(0, numberOfColorsToUse - 1)
    )

    binColors.push(colors[colors.length - 1])
  }

  return binColors.map((color, binIndex) => {
    return {
      value: uniqueValues[binIndex],
      color,
    }
  })
}

export const smallValueSetBinsRequired = (items: any[], maxCount: number) => {
  const uniqueValues = new Set()
  let i = 0
  while (i < items.length) {
    uniqueValues.add(items[i++])
    if (uniqueValues.size > maxCount) return false
  }
  return true
}

/**
 * Get bins that are equally distributed, any rounding to make binSize(0)...binSize(n-1) equal will be added to the last bin such that the binSize(0)...binSize(n-1) != binSize(n)
 * @param colors
 * @param values
 * @returns
 */
export const getBinsEqualDistribution = (
  colors: string[],
  values: number[],
  decimalPrecision?: number,
  allowSmallValueSetBins: boolean = true
): ColorBin[] => {
  const binCount = colors.length

  if (smallValueSetBinsRequired(values, colors.length) && allowSmallValueSetBins)
    return getBinsForSmallValueSet(colors, values)

  const [min, max] = getArrayBounds(values)
  const valuesRange = max - min
  const binValueDecimalPrecision =
    decimalPrecision ?? getDecimalPrecisionEqualDistribution(values, binCount)
  const binSize = floor(valuesRange / binCount, binValueDecimalPrecision)

  return colors.map((_, binIndex) => {
    const isFirstBin = binIndex === 0
    let binMin
    if (isFirstBin) {
      if (min < 0.1) {
        binMin = round(min, 2)
      } else if (min < 1) {
        binMin = round(min, 1)
      } else {
        binMin = round(min, Math.max(binValueDecimalPrecision, 0))
      }
    } else {
      binMin = round(min + binSize * binIndex, binValueDecimalPrecision)
    }

    const isLastBin = binIndex === binCount - 1
    const binMax = isLastBin
      ? ceil(max, Math.max(binValueDecimalPrecision, 0))
      : round(min + binSize * (binIndex + 1), binValueDecimalPrecision)

    return {
      value: binMax,
      displayMin: binMin,
      displayMax: binMax,
      color: colors[binIndex],
    }
  })
}

/**
 * The decimal precision (e.g. used in `round()` or `floor()`) needed to display these values in a readable manner in the map legend
 * @param values array of values that will be displayed
 * @param binCount the number of color bins to break the values into
 */
export const getDecimalPrecisionEqualDistribution = (
  values: number[],
  binCount: number
): number => {
  const [min, max] = getArrayBounds(values)
  const range = max - min

  // Threshold for rounding to nearest hundred
  if (range > binCount * 100) return -2

  // Threshold for rounding to nearest tens
  if (range > binCount * 10) return -1

  // Threshold for rounding to nearest integer
  if (range > binCount * 1) return 0

  // Threshold for rounding to nearest tenth
  if (range > binCount * 0.1) return 1

  // Default to round to nearest hundredth
  return 2
}

const getBinsQuantileDistribution = (colors: string[], values: number[]): ColorBin[] => {
  if (!values.length) {
    return [
      {
        value: NaN,
        color: DEFAULT_COLOR,
      },
    ]
  }

  // When number of unique values is smaller than the number of colors, use getBinsForSmallValueSet
  if (smallValueSetBinsRequired(values, colors.length))
    return getBinsForSmallValueSet(colors, values)

  const binCount = colors.length

  const [min, max] = getArrayBounds(values)
  const quantiles = colors.map((_, index) => quantile(values, (index + 1) / binCount)) as number[]

  // If there are fewer unique bins than colors, it means a bin value is repeated,
  // so we will use linear binning to display the data
  if (new Set(quantiles).size < binCount) {
    return getBinsEqualDistribution(colors, values)
  }

  return quantiles.map((val, binIndex) => {
    const isLastBin = binIndex === binCount - 1
    const isFirstBin = binIndex === 0

    const binMin = isFirstBin ? min : quantiles[binIndex - 1]
    const binMax = isLastBin ? max : quantiles[binIndex]

    let displayMin
    let displayMax

    // Use the size of the bin range to determine the number of decimals to display
    const decimalsToDisplay = -1 * Math.floor(Math.log10(binMax - binMin))
    if (decimalsToDisplay > 0) {
      displayMin = round(binMin, decimalsToDisplay)
      displayMax = round(binMax, decimalsToDisplay)
      // If the bin range is big enough that we don't need to display decimals, we
      // display a rounded number using precision with significant digits
    } else {
      displayMin = roundPreciseNumber(binMin, 2)
      displayMax = roundPreciseNumber(binMax, 2)
    }

    return {
      value: Number(val),
      displayMin: binMin <= binMax ? displayMin : 0,
      displayMax,
      color: val ? colors[binIndex] : DEFAULT_COLOR,
    }
  })
}

export const generateMapboxStyleStepBins = (bins: ColorBin[]): (string | number)[] => {
  if (bins.length === 0 || !bins[0]?.color) return [DEFAULT_COLOR, 0, DEFAULT_COLOR]
  if (bins.length === 1) return [bins[0].color, 0, bins[0].color]
  return bins.reduce<(number | string)[]>((previousValues, { value, color }, idx) => {
    if (idx === bins.length - 1) return [...previousValues, color]
    // To prevent bin values that don't ascend because one has min == max
    if (previousValues.length > 1 && previousValues[previousValues.length - 2] == value)
      return previousValues

    return [...previousValues, color, (value as number) * (1 + Number.EPSILON)]
  }, [])
}

export class DiscreteQuantileMapColorScale implements MapColorScale {
  private colors: string[]
  private values: number[]
  bins: ColorBin[]

  constructor(colors: string[], values: number[], options?: { bins: ColorBin[] }) {
    if (!colors.length) throw new RangeError('Colors array must not be empty')
    this.colors = colors
    this.values = values
    this.bins =
      options?.bins ??
      getBinsQuantileDistribution(
        this.colors,
        this.values.filter(value => value !== 0)
      )
  }

  get mapboxStyleStepBins(): (string | number)[] {
    return generateMapboxStyleStepBins(this.bins)
  }

  /**
   * Iterate through the bins until the value is greater than the bin max, returning the last bin where the value was less than or equal to the bin max
   * @param value the number value to map to a color
   * @returns color string
   */
  getMapColor(value?: number): string {
    if (!value) return DEFAULT_COLOR

    let binIndex = 0
    while (binIndex < this.bins.length - 1 && value > Number(this.bins[binIndex].value)) {
      binIndex++
    }

    return this.bins[binIndex].color
  }
}

export const generateDivergingBins = (
  range: number,
  negativeColors: string[],
  positiveColors: string[]
) => {
  return [
    ...getBinsEqualDistribution(negativeColors, [-range, -0.01], 2, false),
    ZERO_BIN,
    ...getBinsEqualDistribution(positiveColors, [0.01, range], 2, false),
  ]
}
