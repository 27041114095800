import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { Dropdown } from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'
import { PolicyTypeId } from '@/models/spatialPolicyTypes'

import { PolicyType as PolicyTypeEnumClass } from '../../../../utils/types'

import { HideableField, SelectionTags } from './components'
import { EditableFieldProps } from './types'

export const PolicyType = observer(({ policy, ...props }: { policy: Policy }) => {
  const { t } = useTranslation()

  const text = policy.policyType.title
  return (
    <HideableField
      hidden={!text}
      titleText={t('policiesLibrary.policyType', 'Policy Type')}
      {...props}
    >
      {text && <SelectionTags values={[text]} />}
    </HideableField>
  )
})

export const EditablePolicyType = observer(
  ({ clearErrors, editable, onValidate, policy, ...props }: EditableFieldProps) => {
    const { t } = useTranslation()

    return editable ? (
      <HideableField editable titleText={t('policiesLibrary.policyType', 'Policy Type')} {...props}>
        <Dropdown
          error={props.error}
          name="parkingFeeType"
          onBlur={onValidate}
          onChange={policyType => {
            !Array.isArray(policyType) && policy.setPolicyType(policyType as PolicyTypeId)
            clearErrors && clearErrors()
          }}
          options={PolicyTypeEnumClass.values().map(type => type.dropdownOption)}
          placeholder={t('policiesLibrary.formSelectTypeTitle', 'Select a Policy Type')}
          value={policy.policyType.id}
        />
      </HideableField>
    ) : (
      <PolicyType policy={policy} {...props} />
    )
  }
)
