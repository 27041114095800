import _ from 'lodash'
import { observer } from 'mobx-react'

import * as UI from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'
import i18n from '@/modules/i18n/i18n'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'

import { PolicyGroup, PolicyType } from '../../../../utils/types'

import { HideableField } from './components'
import { EditableFieldProps } from './types'

export const Value = observer(({ policy, ...props }: { policy: Policy }) => {
  const {
    data: { currencyCode },
  } = useCurrentRegion()
  return (
    <HideableField
      hidden={
        (policy.policyType === PolicyType.PARKING_FEES &&
          !_.includes(policy.parkingFeeType, 'fixed')) ||
        !policy.value
      }
      titleText={getTitleText(policy)}
      {...props}
    >
      <UI.CurrencyInput
        editable={false}
        currencyCode={currencyCode}
        name="value"
        onChange={() => {}}
        value={policy.value?.toString() || ''}
      />
    </HideableField>
  )
})

export const EditableValue = observer(
  ({ editable, onValidate, policy, ...props }: EditableFieldProps) => {
    const {
      data: { currencyCode },
    } = useCurrentRegion()
    if (!editable) return <Value policy={policy} {...props} />
    return (
      <HideableField editable titleText={getTitleText(policy)} {...props}>
        <UI.CurrencyInput
          editable
          currencyCode={currencyCode}
          error={props.error}
          name="value"
          onBlur={onValidate}
          onChange={value => policy.setValue(value ? parseFloat(value) : null)}
          value={policy.value?.toString() || ''}
        />
      </HideableField>
    )
  }
)

const getTitleText = (policy: Policy) => {
  const { t } = i18n

  let titleText = 'Price'
  switch (policy.policyGroup) {
    case PolicyGroup.PARKING:
      titleText += ' Per Event'
      break
    case PolicyGroup.VEHICLE:
      titleText += ' Per Vehicle'
      break
    case PolicyGroup.TRIP:
      titleText += ' Per Trip'
      break
  }
  return t(`policiesLibrary.formValue${titleText.replaceAll(' ', '')}Title`, titleText)
}
