import { useTranslation } from 'react-i18next'

import { Dropdown, DropdownProps } from '../../Dropdown'
import { DatePickerDropdownValue } from '../types'

interface DateRangeDropdownProps extends DropdownProps {
  dropdownOptions: DatePickerDropdownValue[]
}

export const DateRangeDropdown = ({
  dropdownOptions,
  ...props
}: Omit<DateRangeDropdownProps, 'options'>) => {
  const { t } = useTranslation()

  const dateRangeOptions: { text: string; value: DatePickerDropdownValue }[] = [
    { text: t('dateRangeOptions.custom', 'Custom'), value: 'custom' },
    { text: t('dateRangeOptions.thisMonth', 'This Month'), value: 'thisMonth' },
    { text: t('dateRangeOptions.lastMonty', 'Last Month'), value: 'lastMonth' },
    { text: t('dateRangeOptions.singleDay', 'Single Day'), value: 'singleDay' },
    { text: t('dateRangeOptions.noStartDate', 'No Start Date'), value: 'noStart' },
    { text: t('dateRangeOptions.noEndDate', 'No End Date'), value: 'noEnd' },
  ]

  const filteredOptions = dateRangeOptions.filter(option => dropdownOptions.includes(option.value))

  return <Dropdown {...props} options={filteredOptions} data-testid="date-range-dropdown" />
}
