import { NavLink, To } from 'react-router'
import classNames from 'classnames'

import {
  ExpandableShelfItem,
  ExpandableShelfItemProps,
} from '@/components/Shelf/ExpandableShelfItem'

export interface ShelfItemNavLinkProps extends ExpandableShelfItemProps {
  to: To
}

/**
 * Shelf items that link directly to a page using react-router NavLink component
 */
export const ShelfItemNavLink = ({ to, ...props }: ShelfItemNavLinkProps) => {
  return (
    <NavLink className={classNames({ 'pointer-events-none': props.disabled })} to={to}>
      {({ isActive }) => <ExpandableShelfItem active={isActive} {...props} />}
    </NavLink>
  )
}
