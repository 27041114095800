import { forwardRef, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { DateTime } from 'luxon'

import { Field } from '../../Field'
import { Input } from '../../Input'
import { DateInputProps, getDatePickerErrors } from '../types'
import { dateToText, isDateMatch, isNotNil, isValidDateInputText, textToDate } from '../utils'

import './index.scss'

/**
 * Date input component used to show 'from' and 'to' inputs
 * - user can only input numbers, '.', or '/'
 * - `setDate` will only invoke with a valid date or NULL value
 * - if a user inputs an incomplete or incorrect date (e.g., '30/30/2020', '11/') it will store this value and show an error
 * - luxon will localize based on the browser
 * @param appearFocused - whether the input should appear focused even if it's not (i.e., user is editing this value in the Calendar)
 * @param date - javascript `Date` object or NULL
 * @param errorText - whether an error exists for the input and what message to show
 * @param handleError - function to invoke when there's an error onBlur
 * @param handleFocus - function to invoke onFocus
 * @param setDate - callback for setting date value
 */
const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  ({ appearFocused, date, errorText, handleError, handleFocus, setDate, ...props }, ref) => {
    const [editingValue, setEditingValue] = useState<string>() // user-inputted value shown when focused or in error state
    const [focused, setFocused] = useState(false) // tracks whether the input is focused

    const error = !!errorText

    // memoized because this only needs to run once
    const placeholder = useMemo(() => placeholderText(), [])

    // reset editing value if date changes to null
    useEffect(() => {
      if (!isNotNil(date)) setEditingValue('')
    }, [date])

    /**
     * Invoked when input is focused \
     * Syncs editing value with date if no error \
     * If there is an error, the input is showing the editing value anyway
     */
    const onFocus = () => {
      if (!error) setEditingValue(dateToText(date))
      handleFocus && handleFocus()
      setFocused(true)
    }

    /**
     * Invoked when input is blurred \
     * Check if the edited value is a valid date
     *  - If valid -> it's already been set by onKeyUp
     *  - If not valid -> show an error
     *  - If NULL -> update date to be NULL
     */
    const onBlur = () => {
      setFocused(false)
      if (isNotNil(editingValue)) {
        const newDate = textToDate(editingValue!)
        if (!newDate.isValid && handleError) handleError(getDatePickerErrors().invalid)
      } else {
        isNotNil(date) && setDate(null)
        handleError && handleError(undefined)
      }
    }

    /**
     * Set edited value but only if valid text (e.g., no letters, etc.)
     */
    const onChange = (e: SyntheticEvent, { value }: { value: string }) =>
      isValidDateInputText(value) && setEditingValue(value)

    /**
     * Check for a valid date on every keyStroke \
     * Set the date if valid and different than the existing date
     */
    const onKeyUp = () => {
      if (isNotNil(editingValue)) {
        const newDate = textToDate(editingValue!)
        if (newDate.isValid) {
          const newDateJS = newDate.toJSDate()
          if (!isDateMatch(newDateJS, date)) {
            setDate(newDateJS)
            handleError && handleError(undefined)
          }
        }
      }
    }

    const inputValue = focused || error ? editingValue : dateToText(date)
    return (
      <Field error={error} errorText={errorText}>
        <Input
          className={classNames(
            'populus-date-input',
            !isNotNil(inputValue) && 'placeholder-value',
            (focused || appearFocused) && 'focused'
          )}
          error={!focused && error}
          icon="Calendar"
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          value={inputValue}
          ref={ref}
          {...props}
        />
      </Field>
    )
  }
)

/**
 * Returns placeholder text for input that will match the local date format \
 * - US => 'DD/MM/YYYY'
 * - London => 'MM/DD/YYYY'
 * - Berlin => 'MM.DD.YYYY'
 */
function placeholderText(): string {
  // get the tokens representing the date format for current local (e.g., 'd/M/yyyyy')
  const tokenPattern = DateTime.fromFormatExplain('', 'D', {
    locale: Intl.DateTimeFormat().resolvedOptions().locale,
  })
    .tokens.map(token => token.val)
    .join('')
  const patterns = {
    'd/M/yyyyy': 'DD/MM/YYYY',
    'd.M.yyyyy': 'DD.MM.YYYY',
    'M/d/yyyyy': 'MM/DD/YYYY',
    'M.d.yyyyy': 'MM.DD.YYYY',
  }
  return tokenPattern in patterns ? patterns[tokenPattern as keyof typeof patterns] : ''
}

DateInput.displayName = 'DateInput'

export default DateInput
