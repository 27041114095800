import { FC, useState } from 'react'
import { NavLink } from 'react-router'
import { Autocomplete, Box, MenuItem, TextField } from '@mui/material'
import { useDebounceValue } from 'usehooks-ts'

import { RegionResponse } from '@/modules/api/openapi/models/RegionResponse'
import { useCurrentRegion, useRegions } from '@/modules/urlRouting/hooks'
import { usePathWithNewRegionId } from '@/modules/urlRouting/utils'

const DEBOUNCE_SEARCH_DELAY = 300

const RegionSelectorMUI: FC = () => {
  const [inputValue, setInputValue] = useState('')

  const [debouncedInputValue] = useDebounceValue(inputValue, DEBOUNCE_SEARCH_DELAY)

  const { data: activeRegion } = useCurrentRegion<RegionResponse>()
  const generatePathWithNewRegionId = usePathWithNewRegionId()

  const { data: filteredRegions } = useRegions({
    ...(inputValue && { search: debouncedInputValue }),
  })

  return (
    <Box sx={{ px: 2.5, mt: 2 }}>
      <Autocomplete
        size="small"
        freeSolo
        filterOptions={x => x}
        value={activeRegion}
        options={filteredRegions?.pages[0].items || ([] as RegionResponse[])}
        getOptionLabel={option => (typeof option === 'string' ? '' : option.text)}
        sx={{ width: '100%' }}
        renderOption={(props, option, state, ownerState) => (
          // @ts-ignore
          // These two libraries aren't too happy about having their typing mashed together,
          // but this works as intended.
          <MenuItem
            key={option.text}
            value={option.regionId}
            component={NavLink}
            to={generatePathWithNewRegionId(option.regionId)}
          >
            {option.text}
          </MenuItem>
        )}
        renderInput={params => (
          <TextField
            {...params}
            label="Region"
            onChange={event => setInputValue(event.target.value)}
          />
        )}
      />
    </Box>
  )
}

export default RegionSelectorMUI
