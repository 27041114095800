/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AggregateByEnum } from '../models/AggregateByEnum'
import type { LayerResponse } from '../models/LayerResponse'
import type { OccupancyAggregationByEnum } from '../models/OccupancyAggregationByEnum'
import type { OccupancyResponse } from '../models/OccupancyResponse'
import type { ParkingCountResponse } from '../models/ParkingCountResponse'
import type { ParkingDateRangeResponse } from '../models/ParkingDateRangeResponse'
import type { ParkingDistrictsResponse } from '../models/ParkingDistrictsResponse'
import type { ParkingDownloadUrlsResponse } from '../models/ParkingDownloadUrlsResponse'
import type { ParkingOperatorDataType } from '../models/ParkingOperatorDataType'
import type { ParkingOperators } from '../models/ParkingOperators'
import type { ParkingStatisticsResponse } from '../models/ParkingStatisticsResponse'
import type { PaymentTypeEnum } from '../models/PaymentTypeEnum'
import type { PopulusPage_LayerResponse_ } from '../models/PopulusPage_LayerResponse_'
import type { PopulusPage_ParkingTransactionResponse_ } from '../models/PopulusPage_ParkingTransactionResponse_'
import type { PopulusPage_PricingEngineResponse_ } from '../models/PopulusPage_PricingEngineResponse_'
import type { TimePeriodEnum } from '../models/TimePeriodEnum'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class CurbParkingInsightsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Parking By Layer Statistics
   * Returns the summed total revenue, transaction count and average paid time
   * per transaction for all parking meter events in a given time period for
   * the provided layer, with all the revenue, paid time and transaction
   * counts assigned to the first hour in which the parking event occurs. Optional
   * operator and payment type filters on the data.
   * @returns ParkingStatisticsResponse Successful Response
   * @throws ApiError
   */
  public getParkingByLayerStatistics({
    regionId,
    shapeLayerUuid,
    startDate,
    endDate,
    startTime,
    endTime,
    daysOfWeek,
    operators,
    paymentTypes,
    shapeUuids,
    dataType,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    /**
     * Filter for records that end after the provided ISO start date
     */
    startDate: string
    /**
     * Filter for records that start on or before the provided ISO end date
     */
    endDate: string
    /**
     * Filter for records that end after the provided ISO start time
     */
    startTime?: number
    /**
     * Filter for records that start on or before the provided ISO start time
     */
    endTime?: number
    /**
     * Filter for days of week
     */
    daysOfWeek?: Array<number>
    /**
     * Filter for records that are in this list of operators
     */
    operators?: Array<string>
    /**
     * Filter for records that are in this list of payment types
     */
    paymentTypes?: Array<PaymentTypeEnum>
    /**
     * Filter for one or more shapes or districts
     */
    shapeUuids?: Array<string>
    /**
     * Selector for payments or sensor based occupancy
     */
    dataType?: ParkingOperatorDataType
  }): CancelablePromise<ParkingStatisticsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/curb_parking_insights/statistics',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      query: {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        daysOfWeek: daysOfWeek,
        operators: operators,
        paymentTypes: paymentTypes,
        shapeUuids: shapeUuids,
        dataType: dataType,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Parking By Layer Legend Values
   * Returns the full array of values for a given metric (eg. total transactions),
   * for all parking meter events in a given time period in the parking districts
   * shape_layer, with all the revenue, paid time and transaction counts assigned
   * to the first hour in which the parking event occurs. Optional operator and
   * payment type filters on the data.
   * @returns number Successful Response
   * @throws ApiError
   */
  public getParkingByLayerLegendValues({
    regionId,
    shapeLayerUuid,
    startDate,
    endDate,
    metric,
    startTime,
    endTime,
    daysOfWeek,
    operators,
    paymentTypes,
    shapeUuids,
    dataType,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    /**
     * Filter for records that end after the provided ISO start date
     */
    startDate: string
    /**
     * Filter for records that start on or before the provided ISO end date
     */
    endDate: string
    metric: string
    /**
     * Filter for records that end after the provided ISO start time
     */
    startTime?: number
    /**
     * Filter for records that start on or before the provided ISO start time
     */
    endTime?: number
    /**
     * Filter for days of week
     */
    daysOfWeek?: Array<number>
    /**
     * Filter for records that are in this list of operators
     */
    operators?: Array<string>
    /**
     * Filter for records that are in this list of payment types
     */
    paymentTypes?: Array<PaymentTypeEnum>
    /**
     * Filter for one or more shapes or districts
     */
    shapeUuids?: Array<string>
    /**
     * Selector for payments or sensor based occupancy
     */
    dataType?: ParkingOperatorDataType
  }): CancelablePromise<Array<number>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/curb_parking_insights/legend_values',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      query: {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        daysOfWeek: daysOfWeek,
        operators: operators,
        paymentTypes: paymentTypes,
        shapeUuids: shapeUuids,
        dataType: dataType,
        metric: metric,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Parking Insights Vector
   * Generates vector tiles for curb parking insights data, split by shape. Also
   * returns an int or float for the metric passed in as a filter.
   * @returns string Successful Response
   * @throws ApiError
   */
  public getCurbParkingInsightsVector({
    regionId,
    shapeLayerUuid,
    z,
    x,
    y,
    startDate,
    endDate,
    startTime,
    endTime,
    daysOfWeek,
    operators,
    paymentTypes,
    shapeUuids,
    dataType,
    ifModifiedSince,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    z: number
    x: number
    y: number
    /**
     * Filter for records that end after the provided ISO start date
     */
    startDate: string
    /**
     * Filter for records that start on or before the provided ISO end date
     */
    endDate: string
    /**
     * Filter for records that end after the provided ISO start time
     */
    startTime?: number
    /**
     * Filter for records that start on or before the provided ISO start time
     */
    endTime?: number
    /**
     * Filter for days of week
     */
    daysOfWeek?: Array<number>
    /**
     * Filter for records that are in this list of operators
     */
    operators?: Array<string>
    /**
     * Filter for records that are in this list of payment types
     */
    paymentTypes?: Array<PaymentTypeEnum>
    /**
     * Filter for one or more shapes or districts
     */
    shapeUuids?: Array<string>
    /**
     * Selector for payments or sensor based occupancy
     */
    dataType?: ParkingOperatorDataType
    ifModifiedSince?: string
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/curb_parking_insights/vector/{z}/{x}/{y}',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
        z: z,
        x: x,
        y: y,
      },
      query: {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        daysOfWeek: daysOfWeek,
        operators: operators,
        paymentTypes: paymentTypes,
        shapeUuids: shapeUuids,
        dataType: dataType,
        if_modified_since: ifModifiedSince,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Parking Insights Occupancy Vector
   * Generates vector tiles for curb parking insights data, split by shape. Also
   * returns an int or float for the metric passed in as a filter.
   * @returns string Successful Response
   * @throws ApiError
   */
  public getCurbParkingInsightsOccupancyVector({
    regionId,
    shapeLayerUuid,
    z,
    x,
    y,
    startDate,
    endDate,
    startTime,
    endTime,
    daysOfWeek,
    operators,
    paymentTypes,
    shapeUuids,
    dataType,
    ifModifiedSince,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    z: number
    x: number
    y: number
    /**
     * Filter for records that end after the provided ISO start date
     */
    startDate: string
    /**
     * Filter for records that start on or before the provided ISO end date
     */
    endDate: string
    /**
     * Filter for records that end after the provided ISO start time
     */
    startTime?: number
    /**
     * Filter for records that start on or before the provided ISO start time
     */
    endTime?: number
    /**
     * Filter for days of week
     */
    daysOfWeek?: Array<number>
    /**
     * Filter for records that are in this list of operators
     */
    operators?: Array<string>
    /**
     * Filter for records that are in this list of payment types
     */
    paymentTypes?: Array<PaymentTypeEnum>
    /**
     * Filter for one or more shapes or districts
     */
    shapeUuids?: Array<string>
    /**
     * Selector for payments or sensor based occupancy
     */
    dataType?: ParkingOperatorDataType
    ifModifiedSince?: string
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/curb_parking_insights/occupancy/vector/{z}/{x}/{y}',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
        z: z,
        x: x,
        y: y,
      },
      query: {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        daysOfWeek: daysOfWeek,
        operators: operators,
        paymentTypes: paymentTypes,
        shapeUuids: shapeUuids,
        dataType: dataType,
        if_modified_since: ifModifiedSince,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Occupancy
   * Returns aggregated parking occupancy data.
   * @returns OccupancyResponse Successful Response
   * @throws ApiError
   */
  public getOccupancy({
    regionId,
    shapeLayerUuid,
    startDate,
    endDate,
    startTime,
    endTime,
    aggregateBy,
    daysOfWeek,
    shapeUuids,
    dataType,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    /**
     * Filter parking occupancy/turnover after the provided ISO start date
     */
    startDate: string
    /**
     * Filter parking occupancy/turnover that started before the provided ISO end date
     */
    endDate: string
    /**
     * Filter parking occupancy/turnover that end after the provided local ISO start time (24 hour format)
     */
    startTime: number
    /**
     * Filter parking occupancy/turnover that start on or before the provided local ISO start time (24 hour format)
     */
    endTime: number
    /**
     * Aggregate records by
     */
    aggregateBy?: OccupancyAggregationByEnum
    /**
     * Filter parking occupancy/turnover for those that apply weekly on ISO days of week
     */
    daysOfWeek?: Array<number>
    /**
     * Filter for one or more shapes or districts
     */
    shapeUuids?: Array<string>
    /**
     * Selector for payments or sensor based occupancy
     */
    dataType?: ParkingOperatorDataType
  }): CancelablePromise<Array<OccupancyResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/curb_parking_insights/occupancy',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      query: {
        aggregate_by: aggregateBy,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        daysOfWeek: daysOfWeek,
        shapeUuids: shapeUuids,
        dataType: dataType,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Parking Transactions
   * Returns disaggregated transaction data from requested dates, vendor,
   * payment type, and source feed. Data is read from processed parking insights
   * parquet files in Google Storage.
   * @returns PopulusPage_ParkingTransactionResponse_ Return a CSV report for download or optionally in json format
   * @throws ApiError
   */
  public getParkingTransactions({
    regionId,
    shapeLayerUuid,
    startDate,
    endDate,
    paymentTypes,
    transactionIds,
    locationIds,
    vendors,
    shapeUuids,
    startTimeGte,
    startTimeLt,
    endTimeGte,
    endTimeLt,
    daysOfWeek,
    durationGte,
    durationLte,
    revenueGte,
    revenueLte,
    page = 1,
    size = 50,
    accept = 'application/json',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    /**
     * Start date filter
     */
    startDate: string
    /**
     * End date filter
     */
    endDate: string
    /**
     * Payment type filters
     */
    paymentTypes?: Array<PaymentTypeEnum>
    /**
     * List of transaction ids to filter with
     */
    transactionIds?: Array<string>
    /**
     * List of location ids to filter with
     */
    locationIds?: Array<string>
    /**
     * Vendor filter
     */
    vendors?: Array<string>
    /**
     * Shapes filter
     */
    shapeUuids?: Array<string>
    /**
     * Start time filter range lower bound value, inclusive
     */
    startTimeGte?: number
    /**
     * Start time filter range upper bound value
     */
    startTimeLt?: number
    /**
     * End time filter range lower bound value, inclusive
     */
    endTimeGte?: number
    /**
     * End time filter range upper bound value
     */
    endTimeLt?: number
    /**
     * Days of week filter
     */
    daysOfWeek?: Array<number>
    /**
     * Duration filter range lower bound value, inclusive, in seconds
     */
    durationGte?: number
    /**
     * Duration filter range upper bound value, inclusive, in seconds
     */
    durationLte?: number
    /**
     * Revenue filter range lower bound value, inclusive, in cents
     */
    revenueGte?: number
    /**
     * Revenue filter range upper bound value, inclusive, in cents
     */
    revenueLte?: number
    page?: number
    size?: number
    accept?: string
  }): CancelablePromise<PopulusPage_ParkingTransactionResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/curb_parking_insights/transactions',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      headers: {
        accept: accept,
      },
      query: {
        startDate: startDate,
        endDate: endDate,
        paymentTypes: paymentTypes,
        transactionIds: transactionIds,
        locationIds: locationIds,
        vendors: vendors,
        shapeUuids: shapeUuids,
        startTimeGte: startTimeGte,
        startTimeLt: startTimeLt,
        endTimeGte: endTimeGte,
        endTimeLt: endTimeLt,
        daysOfWeek: daysOfWeek,
        durationGte: durationGte,
        durationLte: durationLte,
        revenueGte: revenueGte,
        revenueLte: revenueLte,
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Parking Transactions Shape Lookup
   * Get lookup for transactions zone IDs to shapes
   * for a given region, operator, and source feed.
   * @returns string Successful Response
   * @throws ApiError
   */
  public getParkingTransactionsShapeLookup({
    regionId,
    shapeLayerUuid,
    operator,
    sourceFeed,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    /**
     * Operator identifier
     */
    operator: string
    /**
     * Source feed identifier
     */
    sourceFeed: string
  }): CancelablePromise<Record<string, string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/curb_parking_insights/transactions/shape_lookup',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      query: {
        operator: operator,
        source_feed: sourceFeed,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Parking Transactions Statistics
   * Returns the sum total revenue, transaction count, total paid duration,
   * average revenue per transaction, and average paid time per transaction
   * for a filtered set of individual parking transactions.
   * @returns ParkingStatisticsResponse Successful Response
   * @throws ApiError
   */
  public getParkingTransactionsStatistics({
    regionId,
    shapeLayerUuid,
    startDate,
    endDate,
    paymentTypes,
    transactionIds,
    locationIds,
    vendors,
    shapeUuids,
    startTimeGte,
    startTimeLt,
    endTimeGte,
    endTimeLt,
    daysOfWeek,
    durationGte,
    durationLte,
    revenueGte,
    revenueLte,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    /**
     * Start date filter
     */
    startDate: string
    /**
     * End date filter
     */
    endDate: string
    /**
     * Payment type filters
     */
    paymentTypes?: Array<PaymentTypeEnum>
    /**
     * List of transaction ids to filter with
     */
    transactionIds?: Array<string>
    /**
     * List of location ids to filter with
     */
    locationIds?: Array<string>
    /**
     * Vendor filter
     */
    vendors?: Array<string>
    /**
     * Shapes filter
     */
    shapeUuids?: Array<string>
    /**
     * Start time filter range lower bound value, inclusive
     */
    startTimeGte?: number
    /**
     * Start time filter range upper bound value
     */
    startTimeLt?: number
    /**
     * End time filter range lower bound value, inclusive
     */
    endTimeGte?: number
    /**
     * End time filter range upper bound value
     */
    endTimeLt?: number
    /**
     * Days of week filter
     */
    daysOfWeek?: Array<number>
    /**
     * Duration filter range lower bound value, inclusive, in seconds
     */
    durationGte?: number
    /**
     * Duration filter range upper bound value, inclusive, in seconds
     */
    durationLte?: number
    /**
     * Revenue filter range lower bound value, inclusive, in cents
     */
    revenueGte?: number
    /**
     * Revenue filter range upper bound value, inclusive, in cents
     */
    revenueLte?: number
  }): CancelablePromise<ParkingStatisticsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/curb_parking_insights/transactions/statistics',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      query: {
        startDate: startDate,
        endDate: endDate,
        paymentTypes: paymentTypes,
        transactionIds: transactionIds,
        locationIds: locationIds,
        vendors: vendors,
        shapeUuids: shapeUuids,
        startTimeGte: startTimeGte,
        startTimeLt: startTimeLt,
        endTimeGte: endTimeGte,
        endTimeLt: endTimeLt,
        daysOfWeek: daysOfWeek,
        durationGte: durationGte,
        durationLte: durationLte,
        revenueGte: revenueGte,
        revenueLte: revenueLte,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Aggregated Parking Counts
   * @returns ParkingCountResponse Successful Response
   * @throws ApiError
   */
  public getAggregatedParkingCounts({
    regionId,
    shapeLayerUuid,
    startDate,
    endDate,
    startTime,
    endTime,
    daysOfWeek,
    timePeriodType,
    countType,
    operators,
    paymentTypes,
    aggregation,
    shapeUuids,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    /**
     * Filter for records that end after the provided ISO start date
     */
    startDate: string
    /**
     * Filter for records that start on or before the provided ISO end date
     */
    endDate: string
    /**
     * Filter for records that end after the provided ISO start time
     */
    startTime?: number
    /**
     * Filter for records that start on or before the provided ISO start time
     */
    endTime?: number
    /**
     * Filter for days of week
     */
    daysOfWeek?: Array<number>
    /**
     * Group and aggregate results by the given time period
     */
    timePeriodType?: TimePeriodEnum
    /**
     * Return counts for revenue, transactions, average paid time, or average paid revenue
     */
    countType?:
      | 'revenue'
      | 'transactions'
      | 'paid_time'
      | 'paid_time_average'
      | 'paid_revenue_average'
    /**
     * Filter for records that are in this list of operators
     */
    operators?: Array<string>
    /**
     * Filter for records that are in this list of payment types
     */
    paymentTypes?: Array<PaymentTypeEnum>
    /**
     * Aggregate results by either operator or payment type
     */
    aggregation?: AggregateByEnum
    /**
     * Filter for records that are in this list of parking districts
     */
    shapeUuids?: Array<string>
  }): CancelablePromise<Array<ParkingCountResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/curb_parking_insights/aggregated_counts',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      query: {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        daysOfWeek: daysOfWeek,
        timePeriodType: timePeriodType,
        countType: countType,
        operators: operators,
        paymentTypes: paymentTypes,
        aggregation: aggregation,
        shapeUuids: shapeUuids,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Pricing Engine By Shape
   * Returns actual and test parking counts by shape in a standard list format.
   * @returns PopulusPage_PricingEngineResponse_ Successful Response
   * @throws ApiError
   */
  public getPricingEngineByShape({
    regionId,
    shapeLayerUuid,
    startDate,
    endDate,
    lowerTargetOccupancy,
    upperTargetOccupancy,
    maxRateChange,
    priceElasticity,
    orderBy = '+shape_name',
    startTime,
    endTime,
    daysOfWeek,
    operators,
    paymentTypes,
    shapeUuids,
    dataType,
    page = 1,
    size = 50,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    /**
     * Filter for records that end after the provided ISO start date
     */
    startDate: string
    /**
     * Filter for records that start on or before the provided ISO end date
     */
    endDate: string
    /**
     * Lower target limit for calculating the rate change
     */
    lowerTargetOccupancy: number
    /**
     * Upper target limit for calculating the rate change
     */
    upperTargetOccupancy: number
    /**
     * The max dollar amount by which the hourly parking rate can change
     */
    maxRateChange: number
    /**
     * Used for calculating the occupancy effect of a rate change
     */
    priceElasticity: number
    /**
     * metric to sort the results by, prepended by - (desc) or + (asc)
     */
    orderBy?: string
    /**
     * Filter for records that end after the provided ISO start time
     */
    startTime?: number
    /**
     * Filter for records that start on or before the provided ISO start time
     */
    endTime?: number
    /**
     * Filter for days of week
     */
    daysOfWeek?: Array<number>
    /**
     * Filter for records that are in this list of operators
     */
    operators?: Array<string>
    /**
     * Filter for records that are in this list of payment types
     */
    paymentTypes?: Array<PaymentTypeEnum>
    /**
     * Filter for one or more shapes or districts
     */
    shapeUuids?: Array<string>
    /**
     * Selector for payments or sensor based occupancy
     */
    dataType?: ParkingOperatorDataType
    page?: number
    size?: number
  }): CancelablePromise<PopulusPage_PricingEngineResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/curb_parking_insights/pricing_engine',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      query: {
        order_by: orderBy,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        daysOfWeek: daysOfWeek,
        operators: operators,
        paymentTypes: paymentTypes,
        shapeUuids: shapeUuids,
        dataType: dataType,
        lowerTargetOccupancy: lowerTargetOccupancy,
        upperTargetOccupancy: upperTargetOccupancy,
        maxRateChange: maxRateChange,
        priceElasticity: priceElasticity,
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Pricing Engine Vector
   * Generates vector tiles for pricing engine data, split by shape.
   * @returns string Successful Response
   * @throws ApiError
   */
  public getPricingEngineVector({
    regionId,
    shapeLayerUuid,
    z,
    x,
    y,
    startDate,
    endDate,
    lowerTargetOccupancy,
    upperTargetOccupancy,
    maxRateChange,
    priceElasticity,
    startTime,
    endTime,
    daysOfWeek,
    operators,
    paymentTypes,
    shapeUuids,
    dataType,
    ifModifiedSince,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    z: number
    x: number
    y: number
    /**
     * Filter for records that end after the provided ISO start date
     */
    startDate: string
    /**
     * Filter for records that start on or before the provided ISO end date
     */
    endDate: string
    /**
     * Lower target limit for calculating the rate change
     */
    lowerTargetOccupancy: number
    /**
     * Upper target limit for calculating the rate change
     */
    upperTargetOccupancy: number
    /**
     * The max dollar amount by which the hourly parking rate can change
     */
    maxRateChange: number
    /**
     * Used for calculating the occupancy effect of a rate change
     */
    priceElasticity: number
    /**
     * Filter for records that end after the provided ISO start time
     */
    startTime?: number
    /**
     * Filter for records that start on or before the provided ISO start time
     */
    endTime?: number
    /**
     * Filter for days of week
     */
    daysOfWeek?: Array<number>
    /**
     * Filter for records that are in this list of operators
     */
    operators?: Array<string>
    /**
     * Filter for records that are in this list of payment types
     */
    paymentTypes?: Array<PaymentTypeEnum>
    /**
     * Filter for one or more shapes or districts
     */
    shapeUuids?: Array<string>
    /**
     * Selector for payments or sensor based occupancy
     */
    dataType?: ParkingOperatorDataType
    ifModifiedSince?: string
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/curb_parking_insights/pricing_engine/vector/{z}/{x}/{y}',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
        z: z,
        x: x,
        y: y,
      },
      query: {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        daysOfWeek: daysOfWeek,
        operators: operators,
        paymentTypes: paymentTypes,
        shapeUuids: shapeUuids,
        dataType: dataType,
        lowerTargetOccupancy: lowerTargetOccupancy,
        upperTargetOccupancy: upperTargetOccupancy,
        maxRateChange: maxRateChange,
        priceElasticity: priceElasticity,
        if_modified_since: ifModifiedSince,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Parking Statistics By District
   * Returns the summed total revenue, transaction count and average paid time
   * per transaction for all parking meter events in a given time period for
   * the aggregation table matching the provided layer level, with all the revenue,
   * paid time and transaction counts assigned to the first hour in which the
   * parking event occurs. Optional operator and payment type filters on the data.
   * @returns ParkingStatisticsResponse Successful Response
   * @throws ApiError
   */
  public getParkingStatisticsByDistrict({
    regionId,
    layerLevel,
    startDate,
    endDate,
    startTime,
    endTime,
    daysOfWeek,
    operators,
    paymentTypes,
    shapeUuids,
    dataType,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer level to find matching aggregation table
     */
    layerLevel: string
    /**
     * Filter for records that end after the provided ISO start date
     */
    startDate: string
    /**
     * Filter for records that start on or before the provided ISO end date
     */
    endDate: string
    /**
     * Filter for records that end after the provided ISO start time
     */
    startTime?: number
    /**
     * Filter for records that start on or before the provided ISO start time
     */
    endTime?: number
    /**
     * Filter for days of week
     */
    daysOfWeek?: Array<number>
    /**
     * Filter for records that are in this list of operators
     */
    operators?: Array<string>
    /**
     * Filter for records that are in this list of payment types
     */
    paymentTypes?: Array<PaymentTypeEnum>
    /**
     * Filter for one or more shapes or districts
     */
    shapeUuids?: Array<string>
    /**
     * Selector for payments or sensor based occupancy
     */
    dataType?: ParkingOperatorDataType
  }): CancelablePromise<ParkingStatisticsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curb_parking_insights/statistics',
      path: {
        region_id: regionId,
      },
      query: {
        layer_level: layerLevel,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        daysOfWeek: daysOfWeek,
        operators: operators,
        paymentTypes: paymentTypes,
        shapeUuids: shapeUuids,
        dataType: dataType,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Parking Date Range
   * Get min and max dates for parking data for a region
   * @returns ParkingDateRangeResponse Successful Response
   * @throws ApiError
   */
  public getParkingDateRange({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<ParkingDateRangeResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curb_parking_insights/date_range',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Parking Districts
   * @returns ParkingDistrictsResponse Successful Response
   * @throws ApiError
   */
  public getParkingDistricts({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<Array<ParkingDistrictsResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curb_parking_insights/districts',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Parking Download Urls
   * @returns ParkingDownloadUrlsResponse Successful Response
   * @throws ApiError
   */
  public getParkingDownloadUrls({
    regionId,
    startDate,
    endDate,
    vendors,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Start date filter
     */
    startDate: string
    /**
     * End date filter
     */
    endDate: string
    /**
     * Vendor filter
     */
    vendors?: Array<string>
  }): CancelablePromise<Array<ParkingDownloadUrlsResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curb_parking_insights/download_urls',
      path: {
        region_id: regionId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        vendors: vendors,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Occupancy Data Types
   * Returns the available parking insights data types for the region.
   * @returns ParkingOperatorDataType Successful Response
   * @throws ApiError
   */
  public getOccupancyDataTypes({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<Array<ParkingOperatorDataType>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curb_parking_insights/occupancy/data_types',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Parking Transactions Date Range
   * Get min and max dates for parking transaction data for a region
   * @returns ParkingDateRangeResponse Successful Response
   * @throws ApiError
   */
  public getParkingTransactionsDateRange({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<ParkingDateRangeResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curb_parking_insights/transactions/date_range',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Parking Insights Operators
   * Returns a list of parking insights operators for a given region.
   * @returns ParkingOperators Successful Response
   * @throws ApiError
   */
  public getCurbParkingInsightsOperators({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<Array<ParkingOperators>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curb_parking_insights/operators',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Parking Districts Layer
   * @returns LayerResponse Successful Response
   * @throws ApiError
   */
  public getParkingDistrictsLayer({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<LayerResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curb_parking_insights/parking_districts_layer',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Parking Location Layers
   * @returns PopulusPage_LayerResponse_ Successful Response
   * @throws ApiError
   */
  public getParkingLocationLayers({
    regionId,
    page = 1,
    size = 50,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    page?: number
    size?: number
  }): CancelablePromise<PopulusPage_LayerResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curb_parking_insights/location_layers',
      path: {
        region_id: regionId,
      },
      query: {
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
