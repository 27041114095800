import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import {
  AuthError,
  MultiFactorResolver,
  PhoneMultiFactorInfo,
  RecaptchaVerifier,
} from 'firebase/auth'
import { Form, Message } from 'semantic-ui-react'

import { fbAuth, finishMfaSignIn, startMfaSignin, toHome } from '@/modules/authentication'
import { useLocationState } from '@/modules/urlRouting/hooks'

export const LoginFormMFA: FC<{ mfaResolver: MultiFactorResolver }> = ({ mfaResolver }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { from } = useLocationState()
  const recaptchaDivRef = useRef<HTMLDivElement>(null)

  const [mfaCode, setMfaCode] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>()
  const [mfaVerificationId, setMfaVerificationId] = useState<string | null>()
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>()

  useEffect(() => {
    const verifier = recaptchaVerifier
      ? recaptchaVerifier
      : recaptchaDivRef.current
        ? new RecaptchaVerifier(fbAuth, recaptchaDivRef.current!, {
            size: 'invisible',
          })
        : null

    if (!verifier) setRecaptchaVerifier(verifier)
    else {
      const mfaSignin = async (verifier: RecaptchaVerifier, resolver: MultiFactorResolver) => {
        const id = await startMfaSignin(verifier, resolver)
        setMfaVerificationId(id)
      }

      mfaSignin(verifier, mfaResolver)
    }
  }, [recaptchaDivRef])

  const hint = (mfaResolver.hints[0] as PhoneMultiFactorInfo).phoneNumber

  const setError = (authError: AuthError) => {
    setErrorMessage(t(`login.mfaAuthError_${authError.code}`, authError.message))
  }

  const submitMfaCode = async () => {
    if (!mfaVerificationId) {
      setErrorMessage(t(`login.mfaError`, 'Error processingMFA'))
      return
    }

    if (!mfaCode) {
      setErrorMessage(t(`login.mfaCodeRequired`, 'MFA code required'))
      return
    }

    try {
      await finishMfaSignIn(mfaCode, mfaResolver, mfaVerificationId)
      navigate(toHome(from))
    } catch (error: unknown) {
      setError(error as AuthError)
    }
  }

  return (
    <Form>
      <div id="recaptcha-container-id" ref={recaptchaDivRef} />
      <div className="card">
        {errorMessage && (
          <Message negative>
            <Message.Header>{t('login.mfaLoginError', 'Multifactor Error')}</Message.Header>
            <p>{t(`login.mfaLoginError_${errorMessage}`, errorMessage)}</p>
          </Message>
        )}

        <p>
          {t('login.mfaCodeSentToPhone', 'A code is being sent to your phone at {{hint}}', {
            hint,
          })}
        </p>
        <div>
          <Form.Input
            type="text"
            name="mfaCode"
            value={mfaCode}
            icon="stopwatch"
            iconPosition="left"
            placeholder={t('login.mfaPlaceholder', 'Enter your multi-factor code')}
            pattern="^[0-9]*$"
            onChange={e => setMfaCode(e.target.value)}
            required
          />
          <Form.Button fluid color="teal" size="small" type="button" onClick={submitMfaCode}>
            {t('common.submit', 'Submit')}
          </Form.Button>
        </div>
      </div>
    </Form>
  )
}

export default LoginFormMFA
