import { useMemo } from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'

import { SpatialPolicy } from '@/models/spatialPolicy'
import { SpatialPolicyDocument } from '@/models/spatialPolicyTypes'
import { MDSOperatorResponse } from '@/modules/api/openapi/models/MDSOperatorResponse'
import { internalApi } from '@/modules/api/request'
import { useMdsOperators } from '@/modules/operator/hooks'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'

export const useAllowedPolicyOperators = (policy?: SpatialPolicy): MDSOperatorResponse[] => {
  const { data } = useMdsOperators()

  if (!policy || !data?.items) return []

  return policy.operators
    ? data.items.filter(({ slug }) => policy.operators?.includes(slug))
    : data.items
}

export const useMobilityPolicyFromPath = () => {
  const { policyId } = useParams()
  const { data, isLoading, isError } = useMobilityPolicies()
  return useMemo(() => {
    const policyDoc = data?.data.find(policy => policy.constant_policy_uuid === policyId)
    const policy = policyDoc ? new SpatialPolicy(policyDoc as SpatialPolicyDocument) : undefined
    return { policy, isLoading, isError }
  }, [policyId, data, isLoading, isError])
}

export const useMobilityPolicies = () => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useQuery({
    queryKey: [`/regions/${regionId}/policies/list/mobility`],
    queryFn: async () =>
      await internalApi.policiesV1.listPolicies({
        requestBody: { region_id: regionId, policy_category: 'mobility' },
      }),
  })
}
