import { FC } from 'react'
import { ListItem } from '@mui/material'

import { BottomSection } from '@/components/layouts/CurbsNav/types'
import CurbsNavCollapsibleSection from '@/components/layouts/CurbsNavCollapsibleSection'

interface CurbsNavDrawerSwitchSectionProps {
  drawerOpen: boolean
  section: BottomSection
}

const CurbsNavDrawerSwitchSection: FC<CurbsNavDrawerSwitchSectionProps> = ({
  drawerOpen,
  section,
}) => {
  return (
    <CurbsNavCollapsibleSection drawerOpen={drawerOpen} icon={section.icon} title={section.title}>
      {section.items.map(item => (
        <ListItem key={item.title} disablePadding sx={{ display: 'block' }}>
          {item.component}
        </ListItem>
      ))}
    </CurbsNavCollapsibleSection>
  )
}

export default CurbsNavDrawerSwitchSection
