import { useMatch } from 'react-router'

import {
  ExpandableShelfItem,
  ExpandableShelfItemProps,
} from '@/components/Shelf/ExpandableShelfItem'

interface ShelfItemDrawerExpandProps extends ExpandableShelfItemProps {
  activePath?: string
  expanded?: boolean
  regionId?: string
  setActivePath?: (path: string) => void
  setExpanded?: (isOpen: boolean) => void
  path: string
}

/**
 * Shelf item associated with a `path` that represent a grouping of links shown in the drawer
 * @param activePath - path associated with the shelf item currently selected by a user (e.g., `:regionId/mobility/maps/*`)
 * @param expanded - whether the drawer is expanded / visible
 * @param path - path associated with this shelf item
 * @param setActivePath - callback to update active path when a new item is clicked
 * @param setExpanded - callback to expand / close the drawer

 */
export const ShelfItemDrawerExpand = ({
  activePath,
  expanded,
  path,
  setActivePath,
  setExpanded,
  ...props
}: ShelfItemDrawerExpandProps) => {
  const isMatch = useMatch(path)
  const isActivePath = activePath === path
  return (
    <ExpandableShelfItem
      {...props}
      active={activePath ? isActivePath : !!isMatch}
      expandable
      expanded={expanded}
      onClick={() => {
        if (!setExpanded || !setActivePath) return
        if (expanded) {
          isActivePath ? setExpanded(false) : setActivePath(path)
        } else {
          setExpanded(true)
          setActivePath(path)
        }
      }}
    />
  )
}
