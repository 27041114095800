import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { action } from 'mobx'
import { observer } from 'mobx-react'

import { generateEnumTranslations } from '@/common/utils/enum'
import * as UI from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'
import { generateEnumDropdownOptions } from '@/models/spatialPolicyTypes'
import { VariableDuration } from '@/models/variablePriceDuration'
import { Day, DayEnum, VariableSchedule } from '@/models/variablePriceSchedule'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'

import { DropdownWithTags } from '../../components'

import './index.scss'

interface TimeTableProps {
  editable?: boolean
  policy: Policy
}

interface VariableRowProps {
  editable?: boolean
  hideClose: boolean
  onRemove?: () => void
}

interface VariableScheduleRowProps extends VariableRowProps {
  pricingData: VariableSchedule
}

interface VariableDurationRowProps extends VariableRowProps {
  pricingData: VariableDuration
}

const Header = ({
  editable,
  suffix,
  text,
}: {
  suffix: 'duration' | 'schedule'
  text: string[]
  editable?: boolean
}) => {
  return (
    <div className={classNames('timetable-row', suffix, !editable && 'uneditable')}>
      {text.map((val: string) => (
        <UI.Text key={val} styleType="caption">
          {val}
        </UI.Text>
      ))}
    </div>
  )
}

const closeIcon = (onRemove?: () => void) => (
  <UI.Icon
    className="time-table-close-icon"
    color="black-1"
    icon="CloseRounded"
    onClick={onRemove}
  />
)

const VariableScheduleRow = observer(
  ({ editable, hideClose, onRemove, pricingData }: VariableScheduleRowProps) => {
    const {
      data: { currencyCode },
    } = useCurrentRegion()
    const { day, startTime, endTime, price } = pricingData
    return (
      <div className={classNames('timetable-row schedule', !editable && 'uneditable')}>
        <UI.TimeInput
          className="timetable-input-small"
          editable={editable}
          value={startTime || undefined}
          onAfterChange={action(t => (pricingData.startTime = t || null))}
        />
        <UI.TimeInput
          className="timetable-input-small"
          editable={editable}
          value={endTime || undefined}
          onAfterChange={action(t => (pricingData.endTime = t || null))}
        />
        <UI.CurrencyInput
          className="timetable-input-small"
          currencyCode={currencyCode}
          editable={editable}
          value={price === 0 ? '' : price.toString()}
          onChange={action(price => (pricingData.price = Number(price) || 0))}
        />
        {
          <DropdownWithTags
            className="timetable-input-days"
            editable={editable}
            onChange={action(day => (pricingData.day = day as Day))}
            options={generateEnumDropdownOptions(DayEnum, 'DaysOfWeek')}
            placeholder="Days of the Week"
            selectedValue={generateEnumTranslations(DayEnum, 'DayEnum')[day]}
            value={day}
          />
        }
        {!hideClose && closeIcon(onRemove)}
      </div>
    )
  }
)

const VariableDurationRow = observer(
  ({ editable, pricingData, hideClose, onRemove }: VariableDurationRowProps) => {
    const {
      data: { currencyCode },
    } = useCurrentRegion()
    const { endDuration, price, startDuration } = pricingData
    return (
      <div className={classNames('timetable-row duration', !editable && 'uneditable')}>
        <UI.Input
          className="timetable-input-small"
          editable={editable}
          icon="Clock"
          value={startDuration || startDuration === 0 ? startDuration : ''}
          // @ts-ignore
          onChange={action((e, data) => (pricingData.startDuration = parseInt(data.value)))}
        />
        <UI.Input
          className="timetable-input-small"
          editable={editable}
          icon="Clock"
          value={endDuration || endDuration === 0 ? endDuration : ''}
          // @ts-ignore
          onChange={action((e, data) => (pricingData.endDuration = parseInt(data.value)))}
        />
        <UI.CurrencyInput
          className="timetable-input-small"
          currencyCode={currencyCode}
          editable={editable}
          value={price === 0 ? '' : price.toString()}
          onChange={action(price => (pricingData.price = Number(price) || 0))}
        />
        {!hideClose && closeIcon(onRemove)}
      </div>
    )
  }
)

export const TimeTable = observer(({ editable = true, policy }: TimeTableProps) => {
  const { t } = useTranslation()

  const { parkingFeeType, variablePriceDuration, variablePriceSchedule } = policy
  if (
    (parkingFeeType === 'variable_price_schedule' && variablePriceSchedule) ||
    (parkingFeeType === 'variable_price_parking_duration' && variablePriceDuration)
  ) {
    const isSchedule = parkingFeeType === 'variable_price_schedule'
    const dataModel = isSchedule ? variablePriceSchedule! : variablePriceDuration!
    const Row = isSchedule ? VariableScheduleRow : VariableDurationRow

    if (dataModel.prices.length === 0) dataModel.add()
    return (
      <div className="variable-pricing-timetable">
        <Header
          editable={editable}
          suffix={isSchedule ? 'schedule' : 'duration'}
          text={
            isSchedule
              ? [
                  t('policiesLibrary.formStartTime', 'Start Time (24hr)'),
                  t('policiesLibrary.formEndTime', 'End Time (24hr)'),
                  t('policiesLibrary.formPricePerHour', 'Price (Per Hour)'),
                ]
              : [
                  t('policiesLibrary.formStartTimeMin', 'Start Time (min)'),
                  t('policiesLibrary.formEndTimeMin', 'End Time (min)'),
                  t('policiesLibrary.formPricePerHour', 'Price (Per Hour)'),
                ]
          }
        />
        {dataModel.prices.map((data, idx) => (
          <Fragment key={`fragment-${idx}`}>
            <Row
              editable={editable}
              // @ts-ignore
              pricingData={data}
              hideClose={idx === 0 || !editable}
              key={idx}
              onRemove={() => dataModel?.remove(idx)}
            />
            {idx !== dataModel.prices.length - 1 && !editable && (
              <UI.Divider key={`divider-${idx}`} className="uneditable-divider" />
            )}
          </Fragment>
        ))}
        {editable && (
          <UI.Button
            fluid
            icon="Expand"
            onClick={e => {
              e.preventDefault()
              dataModel.add()
            }}
            secondary
            text={t('policiesLibrary.formAddTimeFrame', 'Add Time Frame')}
          />
        )}
      </div>
    )
  }
  return null
})
