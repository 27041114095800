import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { HideableField, SelectionTags } from './components'
import { EditableFieldProps } from './types'

export const Status = observer(
  ({ hidden, policy }: Pick<EditableFieldProps, 'policy' | 'hidden'>) => {
    const { t } = useTranslation()

    return (
      <HideableField
        hidden={hidden}
        titleText={t('policiesLibrary.formPolicyStatusTitle', 'Policy Status')}
      >
        <SelectionTags values={[policy.statusText || t('common.nA', 'N/A')]} />
      </HideableField>
    )
  }
)
