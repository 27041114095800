// @ts-nocheck
import i18n from 'i18next'
import _, { get, includes, isEmpty, map, pull } from 'lodash'
import { DateTime } from 'luxon'
import { computed, makeObservable } from 'mobx'

import { mdsPublicJson, operatorsJson } from '@/modules/initialization/config'
import { monitorMessage } from '@/modules/monitoring'
import { MAP_CONSTANTS } from '@/modules/permissions/constants'
import authStore from '@/stores/authStore'

export class Metro {
  doc: any

  constructor(doc) {
    makeObservable(this, {
      regionId: computed,
      isMDSRegion: computed,
      timezone: computed,
      mdsOperators: computed,
      gbfsOperators: computed,
      operators: computed,
      hasDockedGBFS: computed,
      dockedGBFSColor: computed,
      text: computed,
      allowedMaps: computed,
      hasMap: computed,
      today: computed,
      yesterday: computed,
      defaultStartDate: computed,
      isMetric: computed,
      distanceUnits: computed,
      organizationUUID: computed,
    })

    // validators
    if (!doc.regionId) monitorMessage(`regionId not present ${JSON.stringify(doc)}`)

    this.doc = doc
  }

  get regionId() {
    return this.doc.regionId
  }

  get isMDSRegion() {
    const r = map(mdsPublicJson, 'region')
    return includes(r, this.regionId)
  }

  get timezone() {
    const timezone = this.doc.timezone
    if (!timezone) monitorMessage(`Time zone missing, metro: ${this.text}`)
    return timezone
  }

  get mdsOperators() {
    const mdsOperators = _(mdsPublicJson, () => {})
      .filter(x => x.region === this.regionId)
      .map('operator')
      .value()

    const ret = _(this.operators).keys().intersection(mdsOperators).value()

    return ret
  }

  get gbfsOperators() {
    return _(this.operators)
      .keys()
      .pull(...this.mdsOperators)
      .value()
  }

  get operators() {
    const { allOperators } = authStore

    let operatorsInRegion = get(operatorsJson, this.regionId, {})

    if (allOperators) return operatorsInRegion

    operatorsInRegion = _(operatorsInRegion).pick(authStore.operators).value()

    // if they have access to mobility data but no operators, print warning
    if (authStore.hasMobilityManager && this.hasMap && isEmpty(operatorsInRegion)) {
      monitorMessage(`User ${authStore.email} has access to no operators`)
    }

    return operatorsInRegion
  }

  get hasDockedGBFS() {
    return !!this.doc.dockedGBFSRoot
  }

  get dockedGBFSColor() {
    return this.doc.dockedGBFSColor
  }

  get text() {
    return this.doc.text
  }

  get allowedMaps() {
    const maps = get(this.doc, 'allowedMaps', [])
    return !this.isMDSRegion ? pull(maps, MAP_CONSTANTS.ROUTES) : maps
  }

  get hasMap() {
    return !!this.allowedMaps.length
  }

  // TODO: Deprecate with todayDateTime web/fe/src/common/utils/date.ts
  get today() {
    return DateTime.local()
      .setZone(this.timezone)
      .startOf('day')
      .setZone('utc', { keepLocalTime: true })
  }

  // TODO: Deprecate with yesterdayDateTime web/fe/src/common/utils/date.ts
  get yesterday() {
    return DateTime.local()
      .setZone(this.timezone)
      .minus({ days: 1 })
      .startOf('day')
      .setZone('utc', { keepLocalTime: true })
  }

  // TODO: Deprecate with defaultStartDateTime web/fe/src/common/utils/date.ts
  get defaultStartDate() {
    return DateTime.min(this.today.minus({ days: 10 }), this.yesterday.startOf('month'))
  }

  get isMetric() {
    return this.doc.isMetric
  }

  get distanceUnits() {
    if (this.isMetric) return i18n.t('common.kilometer_abbr', 'km')
    return i18n.t('common.miles_abbr', 'mi')
  }

  get organizationUUID() {
    return this.doc.organizationUUID
  }
}
