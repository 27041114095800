import { ReactNode, SyntheticEvent } from 'react'
import { Link } from 'react-router'
import classNames from 'classnames'
import { Dropdown } from 'semantic-ui-react'

import './index.scss'

interface NavbarDropdownProps {
  children?: ReactNode
  className?: string
  disabled?: boolean
  lazyLoad?: boolean
  onBlur?: (e: SyntheticEvent, data: any) => void
  onClick?: (e: SyntheticEvent, data: any) => void
  onClose?: (e: SyntheticEvent, data: any) => void
  onOpen?: (e: SyntheticEvent, data: any) => void
  open?: boolean
  trigger?: ReactNode
}

export const NavbarDropdown = ({ children, className, ...props }: NavbarDropdownProps) => {
  return (
    <Dropdown className={classNames('navbar-dropdown', className)} icon={null} {...props}>
      {children}
    </Dropdown>
  )
}

interface NavbarDropdownMenuProps {
  children?: ReactNode
  className?: string
}

export const NavbarDropdownMenu = ({ children, className }: NavbarDropdownMenuProps) => {
  return (
    <Dropdown.Menu className={classNames('navbar-dropdown-menu', className)}>
      {children}
    </Dropdown.Menu>
  )
}

interface NavbarDropdownItemProps {
  children?: ReactNode
  className?: string
  disabled?: boolean
  onClick?: (e: SyntheticEvent, data: any) => void
}

export const NavbarDropdownItem = ({ children, className, ...props }: NavbarDropdownItemProps) => {
  return (
    <Dropdown.Item className={classNames('navbar-dropdown-item', className)} {...props}>
      {children}
    </Dropdown.Item>
  )
}

interface NavbarDropdownItemLinkProps extends NavbarDropdownItemProps {
  active?: boolean
  to: string
}

/**
 * Semantic Dropdown.Item wrapped in a ReactRouter.Link component
 */
export const NavbarDropdownItemLink = ({
  active,
  className,
  children,
  disabled,
  to,
}: NavbarDropdownItemLinkProps) => {
  return (
    <Link
      aria-selected={active}
      className={classNames('item navbar-dropdown-item-link', className, disabled && 'disabled')}
      role="option"
      to={to}
    >
      {children}
    </Link>
  )
}

interface NavbarDropdownTriggerProps {
  children?: ReactNode
  className?: string
  disabled?: boolean
  open?: boolean
}

export const NavbarDropdownTrigger = ({
  children,
  className,
  open,
}: NavbarDropdownTriggerProps) => {
  return (
    <div className={classNames('navbar-dropdown-trigger', className, open && 'open')}>
      {children}
    </div>
  )
}
