import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { observer } from 'mobx-react'

import { SpatialPolicy as Policy } from '@/models/spatialPolicy'

import { HideableField } from '../components'
import { EditableFieldProps } from '../types'

import { TimeTable } from './Timetable'

const VARIABLE_PRICES_TITLE = 'Variable Pricing Timetable'

export const VariablePrices = observer(({ policy, ...props }: { policy: Policy }) => {
  const { t } = useTranslation()

  return (
    <HideableField
      hidden={!policy.parkingFeeType || _.includes(policy.parkingFeeType, 'fixed')}
      titleText={t('policiesLibrary.formVariablePricingTitle', VARIABLE_PRICES_TITLE)}
      {...props}
    >
      <TimeTable editable={false} policy={policy} />
    </HideableField>
  )
})

export const EditableVariablePrices = observer(
  ({ editable, policy, ...props }: EditableFieldProps) => {
    const { t } = useTranslation()

    return editable ? (
      <HideableField
        editable
        hidden={!policy.parkingFeeType || _.includes(policy.parkingFeeType, 'fixed')}
        titleText={t('policiesLibrary.formVariablePricingTitle', VARIABLE_PRICES_TITLE)}
        {...props}
      >
        <TimeTable editable policy={policy} />
      </HideableField>
    ) : (
      <VariablePrices policy={policy} {...props} />
    )
  }
)
