import { useCallback, useEffect, useReducer, useState } from 'react'
import { Location } from 'react-router'
import { initializeApp } from 'firebase/app'
import {
  Auth,
  AuthError,
  AuthErrorCodes,
  getAuth,
  getRedirectResult,
  multiFactor,
  MultiFactorResolver,
  OAuthProvider,
  PhoneAuthProvider,
  PhoneInfoOptions,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithRedirect,
  signOut as fbSignOut,
  User,
  UserCredential,
} from 'firebase/auth'

import { LocalStorageKey, removeLocalStorageValue } from '@/common/utils/localStorage'
import { queryClientInstance } from '@/modules/api/queryClient'
import { monitorBreadcrumb, monitorException, monitorMessage } from '@/modules/monitoring'
import { PATHS } from '@/modules/urlRouting/paths'

const FirebaseConfig = JSON.parse(import.meta.env.VITE_FIREBASE_CONFIG)
export const firebaseApp = initializeApp(FirebaseConfig)
export const fbAuth = getAuth(firebaseApp)

fbAuth.useDeviceLanguage()

// Set this to 'true' while testing to avoid sending SMSs
fbAuth.settings.appVerificationDisabledForTesting = import.meta.env.VITEST

export let token: string = ''

const POPULUS_DATACENTER = import.meta.env.VITE_POPULUS_DATACENTER
const POPULUS_ENVIRONMENT = import.meta.env.VITE_POPULUS_ENVIRONMENT
export const publicBucket = `https://public-${POPULUS_ENVIRONMENT}-${POPULUS_DATACENTER}.populus.ai`

// Much of this file originated as Fiery, brought up to date with modern Firebase
// See link here: https://github.com/dtinth/fiery
type DataState<T> =
  | {
      loading: true
      failed: false
      error?: undefined
      data?: T
      retry?: undefined
    }
  | {
      loading: true
      failed: true
      error: Error
      data?: T
      retry?: undefined
    }
  | {
      loading: false
      failed: true
      error: Error
      data?: T
      retry: () => void
    }
  | {
      loading: false
      failed: false
      error?: undefined
      data: T
      retry?: undefined
    }

type DataAction =
  | { type: 'begin loading' }
  | { type: 'value'; data: User }
  | { type: 'error'; error: Error; retry: () => void }

interface DataProvider {
  cacheKey: string
  loadOnce(): Promise<any>
  subscribe(
    onNext: (value: any) => void,
    onError: (e: Error) => void
  ): {
    unsubscribe(): void
  }
}

class DataCache {
  private map = new Map<string, DataCacheEntry>()
  initialDataState: DataState<User> = { loading: true, failed: false }
  reducer(state: DataState<User>, action: DataAction): DataState<User> {
    switch (action.type) {
      case 'begin loading':
        return state.failed
          ? {
              loading: true,
              failed: true,
              error: state.error,
              data: state.data,
            }
          : {
              loading: true,
              failed: false,
              data: state.data,
            }
      case 'value':
        return { loading: false, failed: false, data: action.data }
      case 'error':
        return {
          failed: true,
          loading: false,
          data: state.data,
          error: action.error,
          retry: action.retry,
        }
    }
    return state
  }
  getSource(provider: DataProvider): DataCacheEntry {
    const cacheKey = provider.cacheKey
    if (this.map.has(cacheKey)) {
      return this.map.get(cacheKey)!
    }
    let gone = false
    const source = new DataCacheEntry(provider, () => {
      if (gone) return
      gone = true
      this.map.delete(cacheKey)
    })
    this.map.set(cacheKey, source)
    return source
  }
}

const _cache = new DataCache()

class DataCacheEntry {
  private pending = true
  private promise: Promise<void> | null = null
  private latestData: any = null
  private error: Error | null = null
  private onErrorSimulated: (() => void) | null = null
  private subscriberCount = 0
  constructor(
    public provider: DataProvider,
    private unregister: () => void
  ) {}
  subscribe(dispatch: (action: DataAction) => void) {
    const onNext = (value: any) => {
      this.latestData = value
      this.pending = false
      dispatch({ type: 'value', data: this.latestData })
    }
    const onError = (e: Error) => {
      this.error = e
      this.pending = false
      dispatch({ type: 'error', error: this.error, retry })
    }
    const retry = () => {
      this.unregister()
      dispatch({ type: 'begin loading' })
    }
    this.onErrorSimulated = () => {
      dispatch({ type: 'error', error: this.error!, retry })
    }
    const subscription = this.provider.subscribe(onNext, onError)
    if (this.pending) {
      dispatch({ type: 'begin loading' })
    }
    this.subscriberCount++
    return () => {
      this.subscriberCount--
      if (!this.pending && !this.subscriberCount) {
        this.unregister()
      }
      subscription.unsubscribe()
      this.onErrorSimulated = null
    }
  }
  read() {
    if (this.pending) {
      if (!this.promise) this.promise = this.loadOnce()
      throw this.promise
    }
    if (this.error) {
      setTimeout(() => this.unregister())
      throw this.error
    }
    return this.latestData
  }
  simulateError(e: Error): void {
    this.error = e
    this.pending = false
    if (this.onErrorSimulated) this.onErrorSimulated()
  }
  private async loadOnce() {
    try {
      this.latestData = await this.provider.loadOnce()
    } catch (e: any) {
      this.error = e
    } finally {
      this.pending = false
    }
  }
}

class FirebaseAuthProvider implements DataProvider {
  constructor() {}
  get cacheKey() {
    return 'auth'
  }
  loadOnce(): Promise<any> {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      let shouldUnsubscribeNow = false
      let unsubscribe: (() => void) | null = null
      const tryToUnsubscribe = () => {
        if (unsubscribe) {
          unsubscribe()
        } else {
          shouldUnsubscribeNow = true
        }
      }
      unsubscribe = auth.onAuthStateChanged(
        user => {
          resolve(user)
          tryToUnsubscribe()
        },
        error => {
          reject(error)
          tryToUnsubscribe()
        }
      )
      if (shouldUnsubscribeNow) unsubscribe()
    })
  }
  subscribe(onNext: (value: any) => void, onError: (e: Error) => void): { unsubscribe(): void } {
    const auth = getAuth()
    const unsubscribe = auth.onAuthStateChanged(onNext, onError as any)
    return { unsubscribe }
  }
}

export const getToken = async () => {
  const auth = getAuth(firebaseApp)

  if (!auth.currentUser) throw Error('getToken called before currentUser is set')

  try {
    token = await auth.currentUser.getIdToken()
    return token
  } catch (error: any) {
    if (error.code === AuthErrorCodes.TOKEN_EXPIRED) {
      signOut(false)
    } else {
      throw error
    }
  }
}

export const signOut = (clearLocalStorage: boolean = true) => {
  const auth = getAuth(firebaseApp)
  fbSignOut(auth)
  queryClientInstance.clear()
  if (clearLocalStorage) window.localStorage.removeItem('user')
}

export const useAuth = () => {
  const [authState, dispatch] = useReducer(_cache.reducer, _cache.initialDataState)
  const source = _cache.getSource(new FirebaseAuthProvider())
  useEffect(() => source.subscribe(dispatch), [source])

  return {
    ...authState,
    signOut,
    unstable_read: () => source.read(),
  }
}

export const useSignInWithOIDC = (auth: Auth) => {
  const [error, setError] = useState<AuthError>()
  const [loggedInUser, setLoggedInUser] = useState<UserCredential>()
  const [loading, setLoading] = useState<boolean>(false)

  const signInWithOIDCToken = useCallback(
    async (providerId: string) => {
      setLoading(true)
      setError(undefined)
      try {
        const user = await getRedirectResult(fbAuth)

        if (user) {
          setLoggedInUser(user)
        } else {
          const provider = new OAuthProvider(providerId)
          const { userRedirected } = await signInWithRedirect(auth, provider)

          setLoggedInUser(userRedirected)
        }
      } catch (err) {
        setError(err as AuthError)
      } finally {
        setLoading(false)
      }
    },
    [auth]
  )

  return { signInWithOIDCToken, loggedInUser, loading, error }
}

export const toHome = (from: Location<any> | undefined) => {
  const navigateUserTo =
    from?.pathname && from?.pathname !== PATHS.ROOT ? `${from.pathname}${from.search}` : PATHS.ROOT
  return navigateUserTo
}

export const startEnrollMfa = async (recaptchaVerifier: RecaptchaVerifier, phoneNumber: string) => {
  if (fbAuth.currentUser) {
    const phonePrefix = phoneNumber.startsWith('+') ? '' : '+'

    monitorBreadcrumb('Enrolling MFA phone number')

    return await multiFactor(fbAuth.currentUser)
      .getSession()
      .then(function (multiFactorSession) {
        const phoneInfoOptions = {
          phoneNumber: phonePrefix + phoneNumber,
          session: multiFactorSession,
        }

        const phoneAuthProvider = new PhoneAuthProvider(fbAuth)

        // Send SMS verification code
        return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      })
  }
  return null
}

export const unenrollMultiFactor = async () => {
  if (fbAuth.currentUser) {
    const multiFactorUser = multiFactor(fbAuth.currentUser)

    if (multiFactorUser.enrolledFactors.length > 0) {
      await multiFactor(fbAuth.currentUser).unenroll(multiFactorUser.enrolledFactors[0])

      removeLocalStorageValue(LocalStorageKey.UNENROLL_MFA)
      return true
    }
  }
  return false
}

export const finishEnrollMultiFactor = async (
  verificationId: string | null,
  verificationCode: string
) => {
  if (verificationId && fbAuth.currentUser) {
    // Ask user for the verification code. Then:
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

    // Complete enrollment
    await multiFactor(fbAuth.currentUser).enroll(multiFactorAssertion, 'My phone number')

    return true
  }
  return false
}

export const startMfaSignin = async (
  recaptchaVerifier: RecaptchaVerifier,
  mfaResolver: MultiFactorResolver
) => {
  if (mfaResolver!.hints[0].factorId !== PhoneMultiFactorGenerator.FACTOR_ID) {
    monitorMessage('Only SMS multi-factor is supported.')
    return null
  }

  fbAuth.useDeviceLanguage()

  const phoneInfoOptions: PhoneInfoOptions = {
    multiFactorHint: mfaResolver!.hints[0],
    session: mfaResolver.session,
  }
  const phoneAuthProvider = new PhoneAuthProvider(fbAuth)

  // Send SMS verification code
  return await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
}

export const finishMfaSignIn = async (
  verificationCode: string,
  mfaResolver: MultiFactorResolver,
  mfaVerificationId: string
) => {
  // Get the SMS verification code sent to the user
  if (mfaVerificationId && mfaResolver) {
    const cred = PhoneAuthProvider.credential(mfaVerificationId, verificationCode)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

    await mfaResolver
      .resolveSignIn(multiFactorAssertion)
      .then(() => {
        // User successfully signed in with the second factor phone number.
        monitorBreadcrumb('Login with MFA Success')
      })
      .catch((error: AuthError) => {
        monitorException(error)
        throw error
      })
  }
}
