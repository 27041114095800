import * as UI from '@/components/componentLibrary'
import { DropdownProps } from '@/components/componentLibrary/Dropdown'

import { EditableFieldProps } from './types'

import './components.scss'

interface HideableFieldProps extends Omit<EditableFieldProps, 'policy' | 'onValidate'> {
  children: any
  className?: string
  titleText: string
  tooltipProps?: any
}

interface DropdownWithTagsProps extends DropdownProps {
  editable?: boolean
  selectedValue?: string | string[] | false
}

/**
 * Render an array of tags
 */
export const SelectionTags = ({ values, large }: { values: string[]; large?: boolean }) => {
  return (
    <div className="policies-table-form-selection-tags">
      {values.map(value => (
        <UI.Tag key={value} text={value} large={large} />
      ))}
    </div>
  )
}

/**
 * Render a Dropdown when editable; an array of Tags when not editable
 */
export const DropdownWithTags = ({ editable, selectedValue, ...props }: DropdownWithTagsProps) => {
  if (editable) return <UI.Dropdown {...props} />
  if (selectedValue)
    return <SelectionTags values={Array.isArray(selectedValue) ? selectedValue : [selectedValue]} />
  return null
}

/**
 * Render a field when not hidden
 */
export const HideableField = ({
  children,
  editable,
  hidden,
  required,
  ...props
}: HideableFieldProps) => {
  return hidden ? null : (
    <UI.Field required={editable && required} {...props}>
      {children}
    </UI.Field>
  )
}
