import { CancelablePromise } from '@/modules/api/openapi/core/CancelablePromise'
import { CancelablePromise as ApiV2CancelablePromise } from '@/modules/api/v2/core/CancelablePromise'

export const fileFormats = {
  csv: 'text/csv',
  geojson: 'application/geo+json',
  json: 'application/json',
}

/**
 * Generic type to convert a function's (from openapi) first arg into a type to be used elsewhere
 * such as in a wrapper function/hook.
 * Excludes regionId because this is typically grabbed from the `useRegionFromURLPath` hook.
 *
 * T = typeof request function from the typescript-openapi-codegen
 *
 * Example Usage:
 * OpenApiQueryParams<typeof internalApi.curbs.getCurbPoliciesFilters>
 */
export type OpenApiQueryParams<
  T extends (...args: any) => CancelablePromise<any> | ApiV2CancelablePromise<any>,
> = Parameters<T>[0]

/**
 * Generic type to convert a function's (from openapi) first arg into a type to be used elsewhere
 * such as in a wrapper function/hook.
 * Excludes regionId because this is typically grabbed from the `useRegionFromURLPath` hook.
 *
 * T = typeof request function from the typescript-openapi-codegen
 *
 * Example Usage:
 * OpenApiQueryParamsOmitRegionId<typeof internalApi.curbs.getCurbPoliciesFilters>
 */
export type OpenApiQueryParamsOmitRegionId<
  T extends (...args: any) => CancelablePromise<any> | ApiV2CancelablePromise<any>,
> = Omit<OpenApiQueryParams<T>, 'regionId'>
