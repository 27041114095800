import { FC } from 'react'
import { NavLink, To } from 'react-router'
import CircleIcon from '@mui/icons-material/Circle'
import { Box, ListItemButton, ListItemText } from '@mui/material'

import { POPULUS_TEAL } from '@/common/constants'

interface CurbsNavDrawerLinkProps {
  to: To
  pageTitle: string
  disabled: boolean
}

const CurbsNavDrawerLink: FC<CurbsNavDrawerLinkProps> = ({ to, pageTitle, disabled }) => {
  const xPadding = 7

  if (disabled)
    return (
      <ListItemButton
        disabled
        sx={{
          height: '32px',
          px: xPadding,
        }}
      >
        <ListItemText primary={pageTitle} />
      </ListItemButton>
    )

  return (
    <NavLink to={to}>
      {({ isActive }) => (
        <ListItemButton
          sx={{
            height: '32px',
            px: xPadding,
          }}
        >
          {isActive && (
            <Box position="absolute" sx={{ left: 30, bottom: 6 }}>
              <CircleIcon sx={{ height: 8, color: POPULUS_TEAL }} />
            </Box>
          )}
          <ListItemText
            primary={pageTitle}
            sx={[
              isActive
                ? {
                    color: POPULUS_TEAL,
                  }
                : {
                    color: 'black',
                  },
            ]}
          />
        </ListItemButton>
      )}
    </NavLink>
  )
}
export default CurbsNavDrawerLink
