import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { observer } from 'mobx-react'

import * as UI from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'

import { HideableField } from './components'
import { EditableFieldProps } from './types'

const DAYS_TITLE = 'Days Of The Week'

export const Days = observer(({ policy, ...props }: { policy: Policy }) => {
  const { t } = useTranslation()

  return (
    <HideableField
      titleText={t('policiesLibrary.formDaysOfWeekTitle', DAYS_TITLE)}
      hidden={_.isEmpty(policy.days) || _.includes(policy.parkingFeeType, 'variable')}
      {...props}
    >
      <UI.WeekdaysToggle disabled selectedDays={policy.days || []} onChange={() => {}} />
    </HideableField>
  )
})

export const EditableDays = observer(
  ({ editable, onValidate, policy, ...props }: EditableFieldProps) => {
    const { t } = useTranslation()

    return editable ? (
      <HideableField
        editable
        hidden={_.includes(policy.parkingFeeType, 'variable')}
        titleText={t('policiesLibrary.formDaysOfWeekTitle', DAYS_TITLE)}
        tooltipProps={{
          content: t(
            'policiesLibrary.formDaysTooltip',
            'Policy applies for each day of the week selected.'
          ),
          orientation: 'top',
        }}
        {...props}
      >
        <UI.WeekdaysToggle
          selectedDays={policy.days || []}
          onChange={days => {
            policy.setDays(days)
            onValidate && onValidate()
          }}
        />
      </HideableField>
    ) : (
      <Days policy={policy} {...props} />
    )
  }
)
