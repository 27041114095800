import { FC, PropsWithChildren } from 'react'
import { Navigate, useLocation } from 'react-router'

import LoadAnimation from '@/components/loadAnimation'
import { useAuth } from '@/modules/authentication'
import { monitorBreadcrumb } from '@/modules/monitoring'
import { PATHS } from '@/modules/urlRouting/paths'

const LoginGatekeeper: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  const { loading, data: currentUser } = useAuth()

  if (loading) return <LoadAnimation />

  if (!currentUser) {
    monitorBreadcrumb('LoginGatekeeper: Not Logged in, Pushing to Login')
    return <Navigate to={PATHS.LOGIN} replace state={{ from: location }} />
  }

  return children
}

export default LoginGatekeeper
