import { Suspense } from 'react'
import { MapProvider } from 'react-map-gl'
import { IntercomProvider } from 'react-use-intercom'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { observer } from 'mobx-react'
import { AnalyticsProvider } from 'use-analytics'

import LoadAnimation from '@/components/loadAnimation'
import analytics from '@/modules/analytics'
import { queryClientInstance } from '@/modules/api/queryClient'
import GlobalModalProvider from '@/modules/globalModal/context'
import { Configuration } from '@/modules/initialization/config'
import Online from '@/modules/networkConnection/Online'
import { RootRouter } from '@/modules/urlRouting/routes'

const App = () => {
  return (
    <QueryClientProvider client={queryClientInstance}>
      <Suspense fallback={<LoadAnimation />}>
        <Configuration>
          <SentryErrorBoundary>
            <Online>
              <AnalyticsProvider instance={analytics}>
                <IntercomProvider appId={import.meta.env.VITE_INTERCOM_APP_ID}>
                  <MapProvider>
                    <LocalizationProvider
                      dateAdapter={AdapterLuxon}
                      localeText={{
                        fieldHoursPlaceholder: () => '--',
                        fieldMinutesPlaceholder: () => '--',
                        fieldMeridiemPlaceholder: () => '--',
                      }}
                    >
                      <GlobalModalProvider>
                        <RootRouter />
                      </GlobalModalProvider>
                    </LocalizationProvider>
                  </MapProvider>
                </IntercomProvider>
              </AnalyticsProvider>
            </Online>
          </SentryErrorBoundary>
        </Configuration>
      </Suspense>
      <ReactQueryDevtools buttonPosition="bottom-left" />
    </QueryClientProvider>
  )
}

export default observer(App)
