import { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import { DateTime } from 'luxon'
import { Button, Modal } from 'semantic-ui-react'

import { dateToISO, getYearsFromDateRange } from '@/common/utils/date'
import { formatText } from '@/common/utils/text'
import { Dropdown, Field } from '@/components/componentLibrary'
import {
  getAvailableMonthsByYear,
  getDateWithinMinMaxDates,
  getMonthOptions,
  getYearOptions,
} from '@/components/componentLibrary/MonthPicker/utils'
import { ReportTypeEnum } from '@/modules/api/openapi/models/ReportTypeEnum'
import { useDownloadBikeshareReport, useGetGBFSReportMetaData } from '@/modules/reports/utils'

interface BikeshareStationReportDownloadModalProps {
  onClose: () => void
}

const defaultMinDate = DateTime.fromISO('2020-01-01').toJSDate()
const defaultMaxDate = DateTime.now().toJSDate()

const BikeshareStationReportDownloadModal: FC<BikeshareStationReportDownloadModalProps> = ({
  onClose,
}) => {
  const { t } = useTranslation()

  const downloadReport = useDownloadBikeshareReport()

  const {
    data: { reportMetadata },
  } = useGetGBFSReportMetaData()

  const reportsForRegion = Object.keys(reportMetadata || []).filter(
    report => Object.keys(reportMetadata[report] || []).length
  )

  const downloadOptions = [
    { text: 'Collated GBFS Report', key: 'collation', value: 'collation' },
    { text: 'Aggregated GBFS Report', key: 'aggregation', value: 'aggregation' },
  ].filter(option => reportsForRegion.includes(option.value))

  const [selectedReport, setSelectedReport] = useState<ReportTypeEnum | undefined>(
    downloadOptions?.[0]?.value as ReportTypeEnum
  )
  const [operatorOptions, setOperatorOptions] = useState<string[]>([])
  const [selectedOperator, setSelectedOperator] = useState<string | undefined>(operatorOptions[0])

  const dateRange =
    selectedReport && selectedOperator
      ? reportMetadata[selectedReport][selectedOperator]
      : undefined

  const minDate = dateRange?.minDate
    ? DateTime.fromISO(dateRange.minDate).toJSDate()
    : defaultMinDate
  const maxDate = dateRange?.maxDate
    ? DateTime.fromISO(dateRange.maxDate).toJSDate()
    : defaultMaxDate

  useEffect(() => {
    setOperatorOptions(Object.keys(reportMetadata?.[selectedReport ?? ''] || {}))
  }, [selectedReport])

  useEffect(() => {
    setSelectedOperator(operatorOptions[0])
  }, [operatorOptions])

  const [date, setDate] = useState<Date>(DateTime.fromJSDate(maxDate).toJSDate())
  const [monthsByYear, setMonthsByYear] = useState(getAvailableMonthsByYear(minDate, maxDate))
  const yearsInRange = getYearsFromDateRange(minDate, maxDate)

  useEffect(() => {
    setMonthsByYear(getAvailableMonthsByYear(minDate, maxDate))
  }, [JSON.stringify(minDate), JSON.stringify(maxDate)])

  useEffect(() => {
    setDate(DateTime.fromJSDate(maxDate).toJSDate())
  }, [monthsByYear])

  const updateDate = (value: number, datePart: 'month' | 'year') => {
    const newDate = new Date(date)

    if (datePart === 'month') newDate.setMonth(value)
    if (datePart === 'year') newDate.setFullYear(value)

    const adjustedDate = getDateWithinMinMaxDates({
      date: newDate,
      minDate: minDate,
      maxDate: maxDate,
    })

    setDate(adjustedDate)
  }

  return (
    <Modal open={true} onClose={onClose}>
      <Modal.Content>
        <h3>
          <Trans i18nkey="downloadReportsModal.bikeshareStationReportTitle">
            {t('bikeshareReportModal.title', 'Download Bikeshare Station Report')}
          </Trans>
        </h3>
        <Stack spacing={2} alignItems="flex-start">
          <Stack direction="row" spacing={2}>
            <Field titleText="Report Type">
              <Dropdown
                value={selectedReport}
                options={downloadOptions}
                onChange={value =>
                  typeof value === 'string' && setSelectedReport(value as ReportTypeEnum)
                }
              />
            </Field>
            <Field titleText="Operator">
              <Dropdown
                value={selectedOperator}
                options={operatorOptions.map(operator => ({
                  value: operator,
                  key: operator,
                  text: formatText(operator),
                }))}
                onChange={value => typeof value === 'string' && setSelectedOperator(value)}
              />
            </Field>
          </Stack>
          <Field titleText="Month">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', marginBottom: '10px' }}>
                <Dropdown
                  options={getMonthOptions('to', date, monthsByYear).map(option => ({
                    ...option,
                    text: t(`common.${option.text}`, option.text),
                  }))}
                  onChange={value => updateDate(Number(value), 'month')}
                  value={date?.getMonth().toString()}
                  halfSize
                  data-testid="from-month-dropdown"
                />
                <Dropdown
                  options={getYearOptions('from', yearsInRange)}
                  onChange={value => updateDate(Number(value), 'year')}
                  value={date?.getFullYear().toString()}
                  halfSize
                  data-testid="from-year-dropdown"
                />
              </div>
            </div>
          </Field>
          <Button
            onClick={() => {
              downloadReport(selectedOperator!, dateToISO(date), selectedReport as ReportTypeEnum)
            }}
          >
            Download
          </Button>
        </Stack>
      </Modal.Content>
    </Modal>
  )
}

export default BikeshareStationReportDownloadModal
