import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { fromHHMM, toHHMM } from '@/common/utils/time'
import * as UI from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'
import { PolicyTimeFrameType } from '@/modules/policiesV1/policyLibrary/utils/types'

import { HideableField } from '../components'
import { EditableFieldProps } from '../types'

import './index.scss'

const TIME_FRAME_TITLE = 'Daily Time Frame'
const OVERNIGHT_OPTION_EXPLANATION =
  'Create an overnight policy by selecting start time later than end time (e.g. 21:00 - 06:00). Day of week selections apply to the day on which the policy starts.'
const OVERNIGHT_POLICY_DESCRIPTION = 'This is an overnight policy.'

export const TimeFrame = observer(({ policy, ...props }: { policy: Policy }) => {
  const { t } = useTranslation()

  const hourOnly = policy.policyType.timeframeType === PolicyTimeFrameType.HOURS

  return (
    <HideableField
      className="policies-table-form-time-frame"
      hidden={!(policy.startTime || policy.endTime)}
      titleText={t('policiesLibrary.formTimeFrameTitle', TIME_FRAME_TITLE)}
      tooltipProps={
        policy.isOvernight && {
          content: t('policiesLibrary.formOvernightPolicy', OVERNIGHT_POLICY_DESCRIPTION),
          orientation: 'top',
        }
      }
      {...props}
    >
      <div className="time-frame-column">
        <div>
          <UI.Text styleType="tooltip">
            {hourOnly
              ? t('policiesLibrary.formStartHour', 'Start Hour (24hr)')
              : t('policiesLibrary.formStartTime', 'Start Time (24hr)')}
          </UI.Text>
        </div>
        <UI.TimeInput
          className="time-frame-input"
          editable={false}
          value={fromHHMM(policy.startTime) || undefined}
        />
      </div>
      <div className="time-frame-column">
        <div>
          <UI.Text styleType="tooltip">
            {hourOnly
              ? t('policiesLibrary.formEndHour', 'End Hour (24hr)')
              : t('policiesLibrary.formEndTime', 'End Time (24hr)')}
          </UI.Text>
        </div>
        <UI.TimeInput
          className="time-frame-input"
          editable={false}
          value={fromHHMM(policy.endTime) || undefined}
        />
      </div>
    </HideableField>
  )
})

export const EditableTimeFrame = observer(
  ({ editable, policy, onValidate, ...props }: EditableFieldProps) => {
    const { t } = useTranslation()

    const hourInputOnly = policy.policyType.timeframeType === PolicyTimeFrameType.HOURS

    return editable ? (
      <HideableField
        editable
        className="policies-table-form-time-frame"
        titleText={t('policiesLibrary.formTimeFrameTitle', TIME_FRAME_TITLE)}
        tooltipProps={
          policy.optionalOvernight && {
            content: t('policiesLibrary.formOvernightTooltip', OVERNIGHT_OPTION_EXPLANATION),
            orientation: 'top',
          }
        }
        {...props}
      >
        <div className="time-frame-column">
          <div>
            <UI.Text styleType="tooltip">
              {hourInputOnly
                ? t('policiesLibrary.formStartHour', 'Start Hour (24hr)')
                : t('policiesLibrary.formStartTime', 'Start Time (24hr)')}
            </UI.Text>
          </div>
          <UI.TimeInput
            className="time-frame-input"
            editable
            onAfterChange={value =>
              policy.setStartTime(value === undefined ? value : toHHMM(value))
            }
            hourInputOnly={hourInputOnly}
            value={fromHHMM(policy.startTime) || undefined}
            onBlur={onValidate}
          />
        </div>
        <div className="time-frame-column">
          <div>
            <UI.Text styleType="tooltip">
              {hourInputOnly
                ? t('policiesLibrary.formEndHour', 'End Hour (24hr)')
                : t('policiesLibrary.formEndTime', 'End Time (24hr)')}
            </UI.Text>
          </div>
          <UI.TimeInput
            className="time-frame-input"
            editable
            onAfterChange={value =>
              policy.setEndTime(value === undefined ? undefined : toHHMM(value))
            }
            hourInputOnly={hourInputOnly}
            value={fromHHMM(policy.endTime) || undefined}
            onBlur={onValidate}
          />
        </div>
      </HideableField>
    ) : (
      <TimeFrame policy={policy} {...props} />
    )
  }
)
