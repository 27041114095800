import { generatePath, useLocation, useParams } from 'react-router'

import { useAuth } from '@/modules/authentication'
import { PARAMS } from '@/modules/urlRouting/paths'

export const usePathWithNewRegionId = () => {
  const { pathname } = useLocation()
  const { regionId } = useParams()
  return (newRegionId: string) =>
    generatePathWithRegionId(
      regionId ? pathname.replace(regionId, `:${PARAMS.REGION_ID}`) : pathname,
      newRegionId
    )
}

export const generatePathWithRegionId = (path: string, regionId?: string) =>
  generatePath(path, { [PARAMS.REGION_ID]: regionId })

export const useProfilePageAccess = () => {
  const { data: currentUser } = useAuth()
  return !!currentUser?.providerData.some(provider => provider?.providerId === 'password')
}

export const removeRegionIdFromPath = (path: string | undefined) => {
  if (path === undefined) return ''

  return path.split('/').slice(2).join('/')
}
