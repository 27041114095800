import { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import * as UI from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'
import { isEmpty } from '@/modules/map/utils'

import { HideableField } from '../components'
import { EditableFieldProps } from '../types'

import './index.scss'

const MINIMUM_TITLE = 'Minimum'

export const Minimum = observer(({ policy, ...props }: { policy: Policy }) => {
  const { t } = useTranslation()

  return (
    <HideableField
      hidden={!policy.minimum && policy.minimum !== 0}
      titleText={t('policiesLibrary.formMinimumTitle', MINIMUM_TITLE)}
      {...props}
    >
      <UI.Input
        className="policies-table-form-minimum"
        editable={false}
        name="minimum"
        value={isEmpty(policy.minimum) ? '' : policy.minimum}
      />
    </HideableField>
  )
})

export const EditableMinimum = observer(
  ({ editable, onValidate, policy, ...props }: EditableFieldProps) => {
    const { t } = useTranslation()

    return editable ? (
      <HideableField
        editable
        titleText={t('policiesLibrary.formMinimumTitle', MINIMUM_TITLE)}
        {...props}
      >
        <UI.Input
          className="policies-table-form-minimum"
          editable
          error={props.error}
          name="minimum"
          onBlur={onValidate}
          onChange={(e: SyntheticEvent, { value }: { value: string }) => {
            if (isEmpty(value)) policy.setMinimum(null)
            else if (typeof parseInt(value) === 'number') policy.setMinimum(parseInt(value))
          }}
          placeholder="00"
          value={isEmpty(policy.minimum) ? '' : policy.minimum}
        />
      </HideableField>
    ) : (
      <Minimum policy={policy} {...props} />
    )
  }
)
