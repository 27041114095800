import { FC } from 'react'
import { useNavigate } from 'react-router'
import { useIntercom } from 'react-use-intercom'

import { Button, Text } from '@/components/componentLibrary'
import { InvalidRouteTemplate } from '@/components/layouts'
import { Populus404 } from '@/modules/404Routes/Populus404'
import { PATHS } from '@/modules/urlRouting/paths'

const InvalidRoutePage: FC = () => {
  const navigate = useNavigate()
  const { showNewMessage } = useIntercom()

  return (
    <InvalidRouteTemplate
      imageContent={<Populus404 />}
      primaryTextContent={
        <Text styleType="title-light">Sorry, we can’t seem to find what you’re looking for...</Text>
      }
      secondaryTextContent={
        <Text styleType="body">
          It may be the case that your URL is misspelled, the link you used is broken, or the page
          content has moved somewhere else. If you’re unsure why you can’t find the page you’re
          looking for, please reach out to our support team.
        </Text>
      }
      actionsContent={
        <>
          <Button
            extraWide
            onClick={() => navigate(PATHS.ROOT)}
            small
            text="Return to Populus App"
          />
          <Button
            extraWide
            onClick={() => showNewMessage()}
            small
            text="Contact The Populus Team"
          />
        </>
      }
    />
  )
}

export default InvalidRoutePage
