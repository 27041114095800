import { FC } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'

import LoadAnimation from '@/components/loadAnimation'
import { useAuth } from '@/modules/authentication'
import { startImpersonatingUser } from '@/modules/authentication/impersonation'
import { monitorBreadcrumb } from '@/modules/monitoring'
import { PATHS } from '@/modules/urlRouting/paths'

const Impersonate: FC = () => {
  const location = useLocation()
  const { loading, data: currentUser } = useAuth()
  const navigate = useNavigate()
  const [{ id, email }] = useQueryParams({
    ['id']: withDefault(StringParam, undefined),
    ['email']: withDefault(StringParam, undefined),
  })

  if (loading) return <LoadAnimation />

  if (!currentUser) {
    monitorBreadcrumb('Impersonation: Not Logged in, Pushing to Login')
    return <Navigate to={PATHS.LOGIN} replace state={{ from: location }} />
  }

  if (id && email) {
    monitorBreadcrumb(`Impersonation: Impersonating ${email}`)
    startImpersonatingUser(id, email)
    navigate(PATHS.ROOT)
  } else {
    monitorBreadcrumb('Impersonation: No User Information Provided, Pushing to Root')
    navigate(PATHS.ROOT)
  }

  return undefined
}

export default Impersonate
