import { FC } from 'react'
import { ListItem } from '@mui/material'

import { PageSection } from '@/components/layouts/CurbsNav/types'
import CurbsNavCollapsibleSection from '@/components/layouts/CurbsNavCollapsibleSection'
import CurbsNavDrawerLink from '@/components/layouts/CurbsNavDrawerLink'

interface CurbsNavDrawerPageSectionProps {
  drawerOpen: boolean
  section: PageSection
  testId: string
}

const CurbsNavDrawerPageSection: FC<CurbsNavDrawerPageSectionProps> = ({
  drawerOpen,
  section,
  testId,
}) => {
  return (
    <CurbsNavCollapsibleSection
      drawerOpen={drawerOpen}
      icon={section.icon}
      title={section.title}
      testId={testId}
    >
      {section.pages.map(page => (
        <ListItem
          className="curb-drawer-item"
          key={page.title}
          disablePadding
          sx={{ display: 'block' }}
        >
          <CurbsNavDrawerLink to={page.link} pageTitle={page.title} disabled={page.disabled} />
        </ListItem>
      ))}
    </CurbsNavCollapsibleSection>
  )
}

export default CurbsNavDrawerPageSection
