import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Header, Modal } from 'semantic-ui-react'

import Button from '@/components/componentLibrary/Button'

let updateAvailable = false

export const setUpdateAvailable = () => (updateAvailable = true)

const UpdateModal: FC = () => {
  const { t } = useTranslation()
  return (
    <Modal open={updateAvailable} size="small">
      <Header icon="bell outline" content={t('updateModal.header', 'Populus App Updated')} />
      <Modal.Content>
        <h3>
          {t(
            'updateModal.content',
            'We have released a new version of the Populus App. Click OK to reload.'
          )}
        </h3>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="Checkmark"
          text={t('updateModal.button', 'OK')}
          onClick={() => {
            window.location.reload()
          }}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default UpdateModal
