import { CaptionElementProps } from 'react-day-picker'

import { Text } from '../../Text'

import './index.scss'

/**
 * See all props at
 * https://react-day-picker.js.org/api/DayPicker/#captionElement
 */
export const CaptionElement = ({ date }: CaptionElementProps) => {
  return (
    <div className="populus-calendar-caption">
      <Text styleType="secondary-light">
        {date.toLocaleString('default', { month: 'short', year: 'numeric' })}
      </Text>
    </div>
  )
}
