import { FC, Suspense } from 'react'

import { GlobalModalId, useGlobalModalProvider } from '@/modules/globalModal/context'
import BikeshareStationReportDownloadModal from '@/modules/reports/BikeshareStationReportDownloadModal'
import { DownloadReportsModal } from '@/modules/reports/downloadReportsModal'
import { RawDataExportModal } from '@/modules/reports/rawDataExportModal'
import ProfileModal from '@/modules/user/profileModal'

const GlobalModal: FC = () => {
  const { id, setId } = useGlobalModalProvider()
  return (
    <>
      {id === GlobalModalId.PROFILE_SETTINGS && <ProfileModal onClose={() => setId(undefined)} />}
      {id === GlobalModalId.EXPORT_REPORTS && (
        <DownloadReportsModal onClose={() => setId(undefined)} />
      )}
      {id === GlobalModalId.EXPORT_DATA && <RawDataExportModal onClose={() => setId(undefined)} />}
      {id === GlobalModalId.BIKESHARE_STATION_REPORTS && (
        <Suspense>
          <BikeshareStationReportDownloadModal onClose={() => setId(undefined)} />
        </Suspense>
      )}
    </>
  )
}

export default GlobalModal
