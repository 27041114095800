import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { observer } from 'mobx-react'

import { generateEnumTranslations } from '@/common/utils/enum'
import { Dropdown } from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'
import { AllocationEnum, generateEnumDropdownOptions } from '@/models/spatialPolicyTypes'

import { HideableField, SelectionTags } from './components'
import { EditableFieldProps } from './types'

const ALLOCATION_TITLE = 'Allocation'

export const Allocation = observer(({ policy, ...props }: { policy: Policy }) => {
  const { t } = useTranslation()

  return (
    <HideableField
      hidden={!policy.allocation}
      titleText={t('policiesLibrary.formAllocationTitle', ALLOCATION_TITLE)}
      {...props}
    >
      {policy.allocation && (
        <SelectionTags
          values={[
            policy.allocation &&
              generateEnumTranslations(AllocationEnum, 'AllocationEnum')[
                policy.allocation as keyof typeof AllocationEnum
              ],
          ]}
        />
      )}
    </HideableField>
  )
})

export const EditableAllocation = observer(
  ({ editable, onValidate, policy, ...props }: EditableFieldProps) => {
    const { t } = useTranslation()

    return editable ? (
      <HideableField
        editable
        titleText={t('policiesLibrary.formAllocationTitle', ALLOCATION_TITLE)}
        {...props}
      >
        <Dropdown
          error={props.error}
          onBlur={onValidate}
          onChange={allocation => {
            !_.isArray(allocation) && policy.setAllocation(allocation as Policy['allocation'])
          }}
          options={generateEnumDropdownOptions(AllocationEnum, 'Allocation')}
          placeholder={t(
            'policiesLibrary.formAllocationPlaceholder',
            'Select an allocation method'
          )}
          value={policy.allocation || undefined}
        />
      </HideableField>
    ) : (
      <Allocation policy={policy} {...props} />
    )
  }
)
