import classNames from 'classnames'

import Button from '../Button'
import { Icon, IconName } from '../Icon'
import { OnClickEvent } from '../sharedTypes'
import { Text } from '../Text'

import './index.scss'

export interface BannerProps {
  styleType: 'high-urgency' | 'mid-urgency' | 'low-urgency'
  text: string
  buttonOnClick?: OnClickEvent
  buttonText?: string
  className?: string
  headerText?: string
  icon?: IconName
}

export const Banner = ({
  buttonText,
  className,
  headerText,
  icon,
  styleType,
  text,
  buttonOnClick,
}: BannerProps) => {
  const textColor = styleType === 'high-urgency' ? 'warning' : 'midnight-0'

  return (
    <div className={classNames('populus-banner', headerText && 'header', styleType, className)}>
      {icon && (
        <div className="banner-icon">
          <Icon className="icon" icon={icon} color={textColor} />
        </div>
      )}
      {headerText && (
        <div>
          <Text styleType="primary-light">{headerText}</Text>
        </div>
      )}
      <div>
        <Text styleType="body">{text}</Text>
      </div>
      {buttonText && (
        <Button
          secondary={styleType === 'mid-urgency'}
          small={true}
          text={buttonText}
          warning={styleType === 'high-urgency'}
          onClick={buttonOnClick}
        />
      )}
    </div>
  )
}
