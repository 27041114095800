import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'

import * as UI from '@/components/componentLibrary'
import { allDropDownOptions } from '@/components/componentLibrary/DatePicker'
import { DatePickerDropdownValue } from '@/components/componentLibrary/DatePicker/types'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'

import { HideableField } from '../components'
import { EditableFieldProps } from '../types'

import './index.scss'

const DATE_RANGE_TITLE = 'Effective Dates'

export const DateRange = observer(({ policy, ...props }: { policy: Policy }) => {
  const { t } = useTranslation()

  return (
    <HideableField
      className="policies-table-form-date-range"
      titleText={t('policiesLibrary.formDateRangeTitle', DATE_RANGE_TITLE)}
      {...props}
    >
      <div className="policies-table-form-uneditable-date-range">
        {!(policy.startDate || policy.endDate) && (
          <div>
            <UI.Text styleType="body">
              {t('policiesLibrary.formNoneSelected', 'None Selected')}
            </UI.Text>
          </div>
        )}
        {(policy.startDate || policy.endDate) && (
          <>
            <UI.Icon color="black-1" icon="Calendar" />
            <div>
              <UI.Text styleType="body">
                {policy.startDate?.toLocaleString(DateTime.DATE_SHORT) ||
                  t('policiesLibrary.formNoStart', 'No Start Date')}
              </UI.Text>
            </div>
            <div className="policies-table-form-date-range-divider">
              <UI.Divider color="standard" />
            </div>
            <UI.Icon color="black-1" icon="Calendar" />
            <div>
              <UI.Text styleType="body">
                {policy.endDate?.toLocaleString(DateTime.DATE_SHORT) ||
                  t('policiesLibrary.formNoEnd', 'No End Date')}
              </UI.Text>
            </div>
          </>
        )}
      </div>
    </HideableField>
  )
})

export const EditableDateRange = observer(
  ({ editable, onValidate, policy, ...props }: EditableFieldProps) => {
    const { t } = useTranslation()

    return editable ? (
      <HideableField
        className="policies-table-form-date-range"
        titleText={t('policiesLibrary.formDateRangeTitle', DATE_RANGE_TITLE)}
        {...props}
      >
        <UI.DatePicker
          range={{
            from: policy.startDate?.toJSDate() || null,
            to: policy.endDate?.toJSDate() || null,
          }}
          setRange={range => {
            policy.setStartDate(range.from ? DateTime.fromJSDate(range.from) : undefined)
            policy.setEndDate(range.to ? DateTime.fromJSDate(range.to) : undefined)
            // check for errors whenever this is set
            onValidate && onValidate()
          }}
          dropdownOptions={allDropDownOptions.filter(
            (option: DatePickerDropdownValue) => option !== 'thisMonth' && option !== 'lastMonth'
          )}
        />
      </HideableField>
    ) : (
      <DateRange policy={policy} {...props} />
    )
  }
)
