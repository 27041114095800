import { FC } from 'react'
import classNames from 'classnames'

import { Icon } from '../Icon'
import { Text } from '../Text'

import './index.scss'

interface BackButtonProps {
  className?: string
  text: string
  onClick?: () => void
}

export const BackButton: FC<BackButtonProps> = ({ text, onClick, className }) => {
  return (
    <div className={classNames('back-button', className)}>
      <Icon className="back-arrow" color="midnight-0" icon="ArrowLeft" />
      <Text className="back-text" onClick={onClick} styleType="link">
        {text}
      </Text>
    </div>
  )
}
