import classNames from 'classnames'

import { Text } from '../../Text'
import { DayElementProps } from '../types'
import { isLastDayOfMonth } from '../utils'

import './index.scss'

/**
 * Component passed into Calendar that renders each day
 * @param modifiers - see https://react-day-picker.js.org/docs/matching-days
 */
export const DayElement = ({ activeInput, date, modifiers, singleDay }: DayElementProps) => {
  const { selected, start, end, today } = modifiers
  return (
    <div
      className={classNames(
        'populus-calendar-day',
        start && 'start',
        end && 'end',
        selected && 'selected',
        ((activeInput === 'from' && start) || (activeInput === 'to' && end)) && 'editing'
      )}
    >
      <Text styleType="body">{date.getDate()}</Text>
      {today && <div className="today-underline" />}
      {selected && !singleDay && (
        <div
          className={classNames(
            'selected-background',
            start && 'start',
            end && 'end',
            date.getDate() === 1 && 'start-month',
            isLastDayOfMonth(date) && 'end-month'
          )}
        />
      )}
    </div>
  )
}
