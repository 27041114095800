import { SyntheticEvent } from 'react'
import { observer } from 'mobx-react'

import * as UI from '@/components/componentLibrary'

import { EditableFieldProps } from '../types'

import './index.scss'

export const PolicyName = observer(({ onValidate, policy, ...props }: EditableFieldProps) => {
  return (
    <UI.Input
      className="policies-table-form-policy-name-input"
      onBlur={onValidate}
      onChange={(e: SyntheticEvent, { value }: { value: string }) => policy?.setPolicyName(value)}
      value={policy?.policyName}
      {...props}
    />
  )
})
