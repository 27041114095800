import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { observer } from 'mobx-react'

import * as UI from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'

import { FormErrors, PolicyFormFieldName } from '../../../utils/types'

import { MinMaxWrapper } from './fields/MinMax'
import { EditableFieldProps } from './fields/types'
import * as Field from './fields'
import { getFields } from './policyTypeFieldValidations'

import './index.scss'

interface FormProps {
  policy: Policy
  editable?: boolean
  errors?: FormErrors
  clearErrors?: () => void
  setErrors?: (errors: FormErrors) => void
}
export const Form = observer(({ policy, editable, errors, clearErrors, setErrors }: FormProps) => {
  const { t } = useTranslation()

  const fields = getFields(policy.policyType) // fields associated with selected policyType

  /**
   * Invoked in fields to check for errors
   * - gets validator function for field
   * - check for an error
   * - updates error state
   */
  const onValidate = (name: PolicyFormFieldName) => {
    const validator = name && fields[name]?.validator
    const error = validator && validator(policy)
    setErrors && setErrors({ ...errors, [name]: error })
  }

  const fieldProps = (field: PolicyFormFieldName): EditableFieldProps => {
    const errorText = errors && field in errors ? errors[field as keyof typeof errors] : undefined
    return {
      editable,
      error: !!errorText,
      errorText,
      onValidate: () => onValidate(field),
      policy,
      required: fields[field]?.required,
    }
  }

  return (
    <form className="policies-details-form">
      {!_.values(errors).every(_.isEmpty) && (
        <UI.Banner
          icon="Alert"
          styleType="high-urgency"
          text={t('policiesLibrary.formResolveErrors', 'Please resolve errors to save.')}
        />
      )}
      <div className="group-status-container">
        {fields.policyType && <Field.Status hidden={!policy.policyUUID} policy={policy} />}
      </div>
      {fields.policyType && (
        <Field.EditablePolicyType {...fieldProps('policyType')} clearErrors={clearErrors} />
      )}
      {fields.description && <Field.EditableDescription {...fieldProps('description')} />}
      {fields.dateRange && <Field.EditableDateRange {...fieldProps('dateRange')} />}
      {fields.days && <Field.EditableDays {...fieldProps('days')} />}
      {fields.timeFrame && <Field.EditableTimeFrame {...fieldProps('timeFrame')} />}
      {fields.shapeLayerUUID && <Field.EditableShapeLayerUUID {...fieldProps('shapeLayerUUID')} />}
      {fields.value && <Field.EditableValue {...fieldProps('value')} />}
      {fields.variablePrices && <Field.EditableVariablePrices {...fieldProps('variablePrices')} />}
      {fields.operators && <Field.EditableOperators {...fieldProps('operators')} />}
      {fields.vehicleTypes && <Field.EditableVehicleTypes {...fieldProps('vehicleTypes')} />}
      {fields.vehicleStates && <Field.EditableVehicleStates {...fieldProps('vehicleStates')} />}
      {fields.allocation && <Field.EditableAllocation {...fieldProps('allocation')} />}
      {fields.vehicleCountMethod && (
        <Field.EditableVehicleCountMethod {...fieldProps('vehicleCountMethod')} />
      )}
      {(fields.minimum || fields.maximum || fields.unit) && (
        <MinMaxWrapper policy={policy}>
          {fields.minimum && <Field.EditableMinimum {...fieldProps('minimum')} />}
          {fields.maximum && <Field.EditableMaximum {...fieldProps('maximum')} />}
          {fields.unit && <Field.EditableUnit {...fieldProps('unit')} />}
        </MinMaxWrapper>
      )}
      {fields.messages && <Field.EditableMessages {...fieldProps('messages')} />}
    </form>
  )
})
