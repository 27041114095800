import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { observer } from 'mobx-react'

import * as UI from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'

import { HideableField } from './components'
import { EditableFieldProps } from './types'

const MESSAGE_TITLE = 'Message'

export const Messages = observer(({ policy, ...props }: { policy: Policy }) => {
  const { t } = useTranslation()

  return (
    <HideableField
      hidden={!_.get(policy.messages, 'en-US')}
      titleText={t('policiesLibrary.formMessageTitle', MESSAGE_TITLE)}
      {...props}
    >
      <div>
        <UI.Text styleType="body">{_.get(policy.messages, 'en-US')}</UI.Text>
      </div>
    </HideableField>
  )
})

export const EditableMessages = observer(
  ({ editable, onValidate, policy, ...props }: EditableFieldProps) => {
    const { t } = useTranslation()

    return editable ? (
      <HideableField
        editable
        titleText={t('policiesLibrary.formMessageTitle', MESSAGE_TITLE)}
        {...props}
      >
        <UI.TextArea
          error={props.error}
          name="message"
          onBlur={onValidate}
          onChange={(e, { value }) => policy.setMessages({ 'en-US': value })}
          placeholder={t(
            'policiesLibrary.formMessagePlaceholder',
            'Leave an optional message for riders here...'
          )}
          value={_.get(policy.messages, 'en-US')}
        />
      </HideableField>
    ) : (
      <Messages policy={policy} {...props} />
    )
  }
)
