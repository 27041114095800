import { valid } from 'semver'

import { setUpdateAvailable } from '@/modules/update'

export const validateApiVersionMatch = (response: Response) => {
  const API_VERSION = valid(response.headers.get('x-api-version'))
  const VITE_REACT_BUNDLE_VERSION = valid(import.meta.env.VITE_REACT_BUNDLE_VERSION)

  if (VITE_REACT_BUNDLE_VERSION && API_VERSION && VITE_REACT_BUNDLE_VERSION !== API_VERSION) {
    setUpdateAvailable()
  }
}
