import { useQuery } from '@tanstack/react-query'

import { internalApi } from '@/modules/api/request'
import { OpenApiQueryParams } from '@/modules/api/types'
import { useCurrentPermissions } from '@/modules/user/hooks'

export const usePermissionsForObj = (
  params: OpenApiQueryParams<typeof internalApi.permissions.getPermissionsForObj>
) =>
  useQuery({
    queryKey: [`/permissions/${params.objType}/${params.objId}`],
    queryFn: async () => await internalApi.permissions.getPermissionsForObj(params),
  })

export const useRolesForUser = (
  params: OpenApiQueryParams<typeof internalApi.users.getRolesForUser>
) =>
  useQuery({
    queryKey: [`/user_roles/${params.userId}`],
    queryFn: async () => await internalApi.users.getRolesForUser(params),
  })

export const useAvailableRolesWithPermissions = () =>
  useQuery({
    queryKey: [`/user_roles/`],
    queryFn: async () => await internalApi.users.getAvailableRolesWithPermissions(),
  })

export const useHasBikeshareStationReportPermissions = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.bikeshareStationReport
}
