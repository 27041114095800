import { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import * as UI from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'
import { isEmpty } from '@/modules/map/utils'

import { HideableField } from '../components'
import { EditableFieldProps } from '../types'

import './index.scss'

const MAXIMUM_TITLE = 'Maximum'

export const Maximum = observer(({ policy, ...props }: { policy: Policy }) => {
  const { t } = useTranslation()
  return (
    <HideableField
      hidden={!policy.maximum && policy.maximum !== 0}
      titleText={t('policiesLibrary.formMaximumTitle', MAXIMUM_TITLE)}
      {...props}
    >
      <UI.Input
        className="policies-table-form-maximum"
        editable={false}
        name="maximum"
        value={isEmpty(policy.maximum) ? '' : policy.maximum}
      />
    </HideableField>
  )
})

export const EditableMaximum = observer(
  ({ editable, onValidate, policy, ...props }: EditableFieldProps) => {
    const { t } = useTranslation()

    return editable ? (
      <HideableField
        editable
        titleText={t('policiesLibrary.formMaximumTitle', MAXIMUM_TITLE)}
        {...props}
      >
        <UI.Input
          className="policies-table-form-maximum"
          editable
          error={props.error}
          name="maximum"
          onBlur={onValidate}
          onChange={(e: SyntheticEvent, { value }: { value: any }) => {
            if (isEmpty(value)) policy.setMaximum(null)
            else if (typeof parseInt(value) === 'number') policy.setMaximum(parseInt(value))
          }}
          placeholder="00"
          value={isEmpty(policy.maximum) ? '' : policy.maximum}
        />
      </HideableField>
    ) : (
      <Maximum policy={policy} {...props} />
    )
  }
)
