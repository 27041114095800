import { FC } from 'react'
import { NavLink } from 'react-router'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import { useCurrentProduct, useCurrentRegion } from '@/modules/urlRouting/hooks'
import { SEGMENTS } from '@/modules/urlRouting/paths'
import {
  useGetCurbsLandingPage,
  useGetMobilityLandingPage,
} from '@/modules/urlRouting/ProductSelector'
import { generatePathWithRegionId } from '@/modules/urlRouting/utils'
import { useCurrentPermissions } from '@/modules/user/hooks'

const ProductSelectorMUI: FC = () => {
  const currentProduct = useCurrentProduct()
  const {
    data: { regionId },
  } = useCurrentRegion()
  const { data: permissions } = useCurrentPermissions()
  const curbLandingPage = useGetCurbsLandingPage()
  const mobilityLandingPage = useGetMobilityLandingPage()

  const productOptions = [
    {
      disabled: !permissions?.organizationSettings.curbManager,
      product: SEGMENTS.CURB,
      landingPage: curbLandingPage,
      text: 'Curb Manager',
    },
    {
      disabled: !permissions?.organizationSettings.mobilityManager,
      product: SEGMENTS.MOBILITY,
      landingPage: mobilityLandingPage,
      text: 'Mobility Manager',
    },
  ]

  return (
    <Box sx={{ px: 2.5, mt: 2 }}>
      <FormControl size="small" sx={{ width: '100%' }}>
        <InputLabel id="product-selector-mui-label">Product</InputLabel>
        <Select
          labelId="product-selector-mui-label"
          label="Product"
          value={productOptions.find(option => option.product === currentProduct)?.text}
        >
          {productOptions.map(option => (
            // @ts-ignore
            // These two libraries aren't too happy about having their typing mashed together,
            // but this works as intended.
            <MenuItem
              key={option.text}
              value={option.text}
              component={NavLink}
              to={generatePathWithRegionId(option.landingPage, regionId)}
            >
              {option.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default ProductSelectorMUI
