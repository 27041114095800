import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { Dropdown } from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'
import { useRoutesVehicleTypeOptions } from '@/modules/routes/internal/util'

import { HideableField, SelectionTags } from './components'
import { EditableFieldProps } from './types'

const VEHICLE_TYPES_TITLE = 'Vehicle Types'

export const VehicleTypes: FC<{ policy: Policy }> = ({ policy, ...props }) => {
  const { t } = useTranslation()
  const vehicleTypeOptions = useRoutesVehicleTypeOptions()

  return (
    <HideableField
      hidden={_.isEmpty(policy.vehicleTypes)}
      titleText={t('policiesLibrary.formVehicleTypesTitle', VEHICLE_TYPES_TITLE)}
      {...props}
    >
      {policy.vehicleTypes && (
        <SelectionTags
          values={policy.vehicleTypes.map(
            type => vehicleTypeOptions.find(option => option.value === type)?.text as string
          )}
        />
      )}
    </HideableField>
  )
}

export const EditableVehicleTypes: FC<EditableFieldProps> = ({
  editable,
  onValidate,
  policy,
  ...props
}) => {
  const { t } = useTranslation()
  const vehicleTypeOptions = useRoutesVehicleTypeOptions()

  return editable ? (
    <HideableField
      editable
      titleText={t('policiesLibrary.formVehicleTypesTitle', VEHICLE_TYPES_TITLE)}
      {...props}
    >
      <Dropdown
        error={props.error}
        name="vehicleTypes"
        onBlur={onValidate}
        onChange={vehicleTypes =>
          _.isArray(vehicleTypes) && policy.setVehicleTypes(vehicleTypes as string[])
        }
        options={vehicleTypeOptions.filter(option => option.value != 'all')}
        placeholder={t('policiesLibrary.formVehicleTypesPlaceholder', 'Select applicable modes')}
        value={policy.vehicleTypes || []}
      />
    </HideableField>
  ) : (
    <VehicleTypes policy={policy} {...props} />
  )
}
